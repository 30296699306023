"use client";

import React from "react";
import { TypographySmall } from "../typography";
import { Skeleton } from "../shadcn/components/ui/skeleton";
import ImageFallback from "../image/ImageFallback";
import { Project, Workspace } from "@palette.tools/model.client";


export const ProjectSidebarList: React.FC<{
  workspaces: Workspace[];
  projects: Project[];
  onClickProject?: (workspace: Workspace, project: Project) => void,
}> = ({
  workspaces,
  projects,
  onClickProject,
}) => {

  const workspaceByProjectId: Record<string, Workspace> = projects.reduce((acc, x, i) => {
    for (let workspace of workspaces) {
      if (((workspace?.links.project || []).map(x => x.id) || []).includes(x.id)) {
        acc[x.id] = workspace;
      }
    }
    return acc;
  }, {} as Record<string, Workspace>);
  const sortedProjects = [...projects].sort((a, b) => (a.data.name || "").localeCompare(b.data.name || "") );

  return <div>
    {sortedProjects.map(project => {
      const workspace: Workspace | undefined = workspaceByProjectId[project.id];
      return <div
          key={project.id}
          onClick={() => onClickProject && onClickProject(workspace, project)}
          className="flex flex-row px-[16px] gap-x-3 h-[55px] rounded-2xl items-center cursor-pointer select-none hover:bg-muted active:bg-black data-[selected=true]:bg-black"
        >
        <ImageFallback
          className="rounded-md object-cover object-center"
          src={project.data.thumbnail_url}
          height="35"
          width="35"
          alt={`Project thumbnail for ${project.data.name}`}
        />
        <div className="flex flex-col">
          <TypographySmall>{project.data.name}</TypographySmall>
          <span className="text-xs text-muted-foreground">{workspace?.data.name}</span>
        </div>
      </div>
    })}
    </div>
}


export const EmptyProjectSidebarList: React.FC = () => {

  return <div>
    {[1, 2, 3].map(i => {
      return <div key={i} className="flex flex-row px-[16px] gap-x-3 h-[55px] rounded-2xl items-center hover:bg-muted active:bg-black data-[selected=true]:bg-black">
        <div
          className="rounded-md object-cover object-center w-[35px] h-[35px]"
        />
        <div className="flex flex-col">
          <TypographySmall><Skeleton className="h-4 w-[100px]" /></TypographySmall>
          <span className="text-xs text-muted-foreground"><Skeleton className="h-4 w-[50px]" /></span>
        </div>
      </div>
    })}
  </div>
}
