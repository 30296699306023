import React from 'react';
//@ts-ignore
import Textfit from 'react-textfit/lib/Textfit';

const TextFill: React.FC<{children: React.ReactNode}> = ({ children }) => {

  const [ready, setReady] = React.useState(false);

  return (
    <div data-ready={ready} className="w-full h-full flex place-content-center items-center opacity-0 data-[ready=true]:opacity-100">
      <Textfit mode="single" max={500} className="flex justify-center items-center" onReady={() => setReady(true)}>
        {children}
      </Textfit>
    </div>
  );
};

export default TextFill;
