"use client";

import { LoadingIcon } from "@palette.tools/react.icons";
import { formatTimestamp, getTimeSinceAbbreviated } from "@palette.tools/utils";
import EmojiPicker, { EmojiClickData, Theme as EmojiTheme } from "emoji-picker-react";
import { ArrowDownIcon, MoreHorizontalIcon, PencilIcon, SendHorizonalIcon, SmileIcon, Trash2Icon } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import ImageFallback from "../image/ImageFallback";
import { BlockingModal, EditCommentModal } from "../modals";
import { Button } from "../shadcn/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../shadcn/components/ui/dropdown-menu";
import { Popover, PopoverContent, PopoverTrigger } from "../shadcn/components/ui/popover";
import { cn } from "../shadcn/lib/utils";
import { TypographyMedium, TypographyMuted } from "../typography";
import { overrideElement } from "../utils";
import { Comment, Project, UserProfile, Workspace, id as uuid, transact, useAuth, Category, Task, getPermissions, FileEntry } from "@palette.tools/model.client";
import { getProfileName } from "@palette.tools/model";
import { getMessageGroupsFromComments } from "@palette.tools/model/src/Comment";
import { delete_entity } from "@palette.tools/api.client";


const CommentItem = overrideElement<'div', {
  comment: Comment,
  canEdit: boolean,
  canDelete: boolean,
  onClickDeleteComment?: (comment: Comment) => void,
  onClickEditComment?: (comment: Comment) => void,
}>(({className, props, customProps: { comment, canEdit, canDelete, onClickDeleteComment, onClickEditComment }}) => {

  const { edited } = comment.data;
  const [timeAgo, setTimeAgo] = useState<string>(comment.data.updated_at ? getTimeSinceAbbreviated(comment.data.updated_at) : "");

  let items: React.ReactNode[] = [];

  if (canEdit) {
    const item = <DropdownMenuItem
      key="edit"
      onClick={(e) => {
        onClickEditComment?.(comment);
      }}>
      <PencilIcon width={16} height={16} />&nbsp;&nbsp;<span>Edit</span>
    </DropdownMenuItem>;
    items.push(item);
  }

  if (canDelete) {
    const item = <DropdownMenuItem
      key="delete"
      onClick={(e) => {
        onClickDeleteComment?.(comment);
      }}>
      <Trash2Icon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Delete</span>
    </DropdownMenuItem>;
    items.push(item);
  }

  useEffect(() => {
    setTimeAgo(comment.data.updated_at ? getTimeSinceAbbreviated(comment.data.updated_at) : "")
  }, [comment.data.updated_at]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeAgo(comment.data.updated_at ? getTimeSinceAbbreviated(comment.data.updated_at) : "");
    }, 60000); // Update every 60 seconds (1 minute)

    // Cleanup function for unmounting
    return () => clearInterval(intervalId);
  });


  return <div className={cn(className, "whitespace-pre-wrap w-full hover:bg-muted/60 flex flex-row group break-words")} {...props}>
    <div className="flex-1 w-full">
      <span className="break-words">{comment.data.message}</span>
      <span className="text-muted-foreground italic text-xs select-none">
        {edited ? ` (edited ${timeAgo})` : ""}
      </span>
    </div>
    {items.length > 0 ? <><div className="w-[4px]" /><DropdownMenu>
      <DropdownMenuTrigger className="h-full items-start content-start">
        <MoreHorizontalIcon className="opacity-0 group-hover:opacity-100" />
      </DropdownMenuTrigger>
      <DropdownMenuContent>{items}</DropdownMenuContent>
    </DropdownMenu></> : null}
  </div>

}, ['comment', 'canEdit', 'canDelete', 'onClickDeleteComment', 'onClickEditComment']);


const MessageGroupItem: React.FC<{
  users: UserProfile[],
  comments: Comment[],
  canEdit: boolean,
  canDelete: boolean,
  onClickDeleteComment?: (comment: Comment) => void,
  onClickEditComment?: (comment: Comment) => void,
}> = ({
  users,
  comments,
  canEdit,
  canDelete,
  onClickDeleteComment,
  onClickEditComment,
}) => {

  if (!comments || comments.length < 1) {
    return null;
  }

  const user = users.find((user) => user.id === comments[0]?.data.created_by);
  const timestamp = comments[0].data.created_at;

  return (
    <div className="flex flex-row items-start gap-x-[10px] w-full">
      <ImageFallback
        className="rounded-full pointer-events-none"
        src={user?.data.image_url}
        width="35"
        height="35"
        alt={`Profile pic of ${getProfileName(user)}`}
      />
      <div className="flex flex-col gap-x-[10px] flex-1 min-w-0">
        <div className="flex flex-row gap-x-[10px]">
          <TypographyMedium className="bold">{getProfileName(user)}</TypographyMedium>
          {timestamp && <TypographyMuted>{formatTimestamp(timestamp)}</TypographyMuted>}
        </div>
        <div className="flex flex-col gap-x-[10px] w-full break-words">
          {comments.map((comment, i) => <CommentItem
            key={i}
            comment={comment}
            canEdit={canEdit}
            canDelete={canDelete}
            onClickDeleteComment={onClickDeleteComment}
            onClickEditComment={onClickEditComment}
          />)}
        </div>
      </div>
    </div>
  );
};


export const CommentBox: React.FC<{
  comments: Comment[],
  users: UserProfile[],
  workspace: Workspace | null,
  project: Project | null,
  parent?: Category | Task | FileEntry | null,
  currentFrame?: number,
  className?: string,
}> = ({
  comments,
  users,
  workspace,
  project,
  parent,
  currentFrame = 0,
  className,
}) => {

  // Firestore state.
  const { profile } = useAuth();
  const [ submitting, setSubmitting ] = useState(false);
  const [ messageGroups, setMessageGroups ] = useState(getMessageGroupsFromComments(comments));
  const [ targetComment, setTargetComment ] = useState<Comment | undefined>();
  const { canEditComment: canEditTargetComment } = getPermissions({
    profile,
    workspace,
    project,
    category: parent?.key === "category" ? parent : undefined,
    task: parent?.key === "task" ? parent: undefined,
    comment: targetComment,
  });
  const [isDeleting, setDeleting] = useState(false);
  const [isEmojiMenuOpen, setEmojiMenuOpen] = useState(false);
  const emojiTriggerRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setMessageGroups(getMessageGroupsFromComments(comments));
  }, [comments])


  // Scrollbar state.
  const commentsContainerRef = useRef<HTMLDivElement>(null);
  const [showDownArrow, setShowDownArrow] = useState(false);
  const [hideScrollbar, setHideScrollbar] = useState(true);
  const userJustPosted = useRef(false);
  const [userIsScrolling, setUserIsScrolling] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  // Input state.
  const [newMessage, setNewMessage] = useState("");


  const isScrolledToBottom = () => {
    const commentsContainer = commentsContainerRef.current;
    if (commentsContainer) {
      const { scrollTop, scrollHeight, clientHeight } = commentsContainer;
      return scrollTop + clientHeight >= scrollHeight - 10;
    }
    return false;
  };

  const handleScroll = () => {
    if (isScrolledToBottom()) {
      setShowDownArrow(false);
      setHideScrollbar(true);
      setUserIsScrolling(false);
    } else {
      setShowDownArrow(true);
      setHideScrollbar(false);
      setUserIsScrolling(true);
    }
  };

  useEffect(() => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.addEventListener('scroll', handleScroll);
      commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
    }
    return () => {
      if (commentsContainerRef.current) {
        commentsContainerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [commentsContainerRef]);


  const initialLoad = useRef(false);
  useEffect(() => {
    if (!comments || comments.length < 1 || initialLoad.current) return;
    initialLoad.current = true;
    setTimeout(() => {
      if (commentsContainerRef.current) {
        commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
      }
    }, 100); // Adjust the delay as needed
    setTimeout(() => {
      if (commentsContainerRef.current) {
        commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
      }
    }, 1000); // Adjust the delay as needed
    setTimeout(() => {
      if (commentsContainerRef.current) {
        commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
      }
    }, 2000); // Adjust the delay as needed
  }, [comments]);

  useEffect(() => {
    if (commentsContainerRef.current) {
      if (comments && comments.length > 0) {
        if (userJustPosted.current) {
          commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
          userJustPosted.current = false;
        }
        if (!userIsScrolling) {
          commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
        }
      }
      setShowDownArrow(!isScrolledToBottom());
    }
  }, [comments, commentsContainerRef.current, textAreaRef]);

  const handleDownArrowClick = () => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
    }
    setShowDownArrow(false); // Hide the DownArrow
  };

  const onSubmit = (latestText?: string) => {

    const message = latestText || newMessage;

    if (!profile || !workspace || !project || !parent || !(message.trim())) return;

    userJustPosted.current = true;

    setNewMessage("");
    setSubmitting(true);
    const id = uuid();
    transact(
      Comment.create({ id, frame: currentFrame, edited: false, message }, { after: (key, id) => [
        ...workspace.link(key, id),
        ...project.link(key, id),
      ]}),
      parent.link("comment", id),
    ).finally(() => {
      setSubmitting(false);
    })

  }

  return <>
    {isDeleting ? <BlockingModal delay={1000}>Deleting...</BlockingModal> : null}

    {!!targetComment ? <EditCommentModal
      open={!!targetComment}
      onClose={() => setTargetComment(undefined)}
      enabled={canEditTargetComment}
      comment={targetComment}
    /> : undefined}

    <div className={cn("flex flex-col rounded-md bg-muted/25 h-full relative p-[10px] min-h-0 min-w-[480px]", className)}>

      {showDownArrow && (
        <div
          className="absolute t-[20px] r-[40px] cursor-pointer"
          style={{ position: 'absolute', top: 20, right: 40, cursor: 'pointer' }}
          onClick={handleDownArrowClick}
        >
          <ArrowDownIcon size={20} className="stroke-muted-foreground" />
        </div>
      )}

      <div
        ref={commentsContainerRef}
        className={cn("overflow-y-scroll flex flex-col w-full p-[25px] flex-1 min-h-0", hideScrollbar ? 'hide-scrollbar' : '')}
      >

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 'auto',
            rowGap: "10px",
          }}
        >

          {messageGroups.map((comments, i) => {
            const { canEditComment, canDeleteComment } = getPermissions({
              profile,
              workspace,
              project,
              category: parent?.key === "category" ? parent : undefined,
              task: parent?.key === "task" ? parent: undefined,
              comment: comments[0],
            });

            return <MessageGroupItem
              key={i}
              users={users}
              comments={comments}
              canEdit={canEditComment}
              canDelete={canDeleteComment}
              onClickDeleteComment={(comment) => {
                setDeleting(true);
                delete_entity(comment.key, comment.id).finally(async () => {
                  setDeleting(false);
                });
              }}
              onClickEditComment={(comment) => setTargetComment(comment)}
            />
          })}

        </div>
      </div>

      <div className="flex-shrink-0">
        <form
          className="flex flex-col w-full h-auto gap-x-[10px] p-2 rounded-md border-border border-[1px] bg-muted/50 focus-within:ring-2 ring-ring"
          onSubmit={(e) => {e.preventDefault() ; e.stopPropagation() ; onSubmit()}}
        >
          <textarea
            ref={textAreaRef}
            className="resize-none w-full bg-transparent outline-none ring-none border-none"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                onSubmit(e.currentTarget.value);
              }
            }}
            placeholder="Use this for questions, notes, jokes... anything!"
            rows={3}
          />
          <div className="flex flex-row items-center content-center">

            <Popover open={true}>

              <PopoverTrigger
                ref={emojiTriggerRef}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setEmojiMenuOpen(!isEmojiMenuOpen);
                }}
              >
                <SmileIcon width={24} height={24} />
              </PopoverTrigger>

              <PopoverContent
                hidden={!isEmojiMenuOpen}
                className="pointer-events-auto bg-transparent border-none outline-none ring-none p-0 m-0 shadow-none"
                onInteractOutside={e => {
                  if (emojiTriggerRef.current && emojiTriggerRef.current.contains(e.target as Node)) {
                    return;
                  }
                  setEmojiMenuOpen(false);
                }}
              >
                <EmojiPicker
                  lazyLoadEmojis
                  autoFocusSearch
                  theme={EmojiTheme.DARK}
                  onEmojiClick={(newEmoji: EmojiClickData) => {
                    if (textAreaRef.current) {
                      const textarea = textAreaRef.current;
                      const start = textarea.selectionStart;
                      const end = textarea.selectionEnd;

                      // Insert the emoji
                      const updatedText =
                          textarea.value.substring(0, start) +
                          newEmoji.emoji +
                          textarea.value.substring(end);

                      textarea.value = updatedText;

                      // Move the cursor forward
                      textarea.selectionStart = textarea.selectionEnd = start + newEmoji.emoji.length;

                      textarea.focus(); // Keep the focus on the textarea

                      setNewMessage(updatedText);
                    }
                  }}
                />
              </PopoverContent>
            </Popover>
            <div className="flex-1" />
            <Button type="submit" variant="default" size="icon" className="bg-blue-900 hover:bg-blue-700 group" disabled={submitting || !newMessage.trim()}>
              {submitting ? <LoadingIcon /> : <SendHorizonalIcon className="stroke-foreground group:hover:stroke-foreground"/>}
            </Button>
          </div>
        </form>
      </div>
    </div>
  </>
};
