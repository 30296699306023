"use client";

import React, { useState } from "react";
import { Modal } from "./Modal";
import { LoadingIcon } from "@palette.tools/react.icons";
import { TypographyMedium } from "../typography";
import { Input } from "../shadcn/components/ui/input";
import { Label } from "../shadcn/components/ui/label";
import { Asset, Category, Project, Workspace, transact, useAuth, usePermissions, id as uuid } from "@palette.tools/model.client";


export const CreateAssetModal: React.FC<{
  open: boolean,
  onClose: () => void,
  workspace: Workspace | null,
  project: Project | null,
  category: Category | null,
}> = ({
  open,
  onClose,
  workspace,
  project,
  category,
}) => {

  const { profile, isLoading: isLoadingProfile } = useAuth();

  // Permissions hooks.
  const { canCreateAsset } = usePermissions({ workspace, project, category });

  // Regular hooks
  const [name, setName] = useState("");
  const [isCreating, setCreating] = useState(false);

  let content: React.ReactNode;
  if (isLoadingProfile) {
    content = <div className="w-[24px] h-[24px]"><LoadingIcon /></div>;
  }
  else if (profile) {
    if (!canCreateAsset) {
      content = <TypographyMedium className="italic">Sorry, you are not allowed to create items here!</TypographyMedium>
    }
    else {
      content = <><Label htmlFor="name">Name</Label>
        <Input
          autoFocus
          name="name"
          type="text"
          placeholder="Name of your item..."
          onChange={(e) => setName(e.target.value)}
        />
      </>
    }
  }
  else {
    content = <TypographyMedium className="text-destructive">An error occurred!</TypographyMedium>
  }

  const submitDisabled = (
    !name.trim()
    || isLoadingProfile
    || !workspace
    || !project
    || !category
    || !canCreateAsset
  );

  return <Modal
    open={open}
    header={"Create Item"}
    description={""}
    primaryButtonContent={isCreating ? <><div className="w-[16px] h-[16px]"><LoadingIcon /></div>{" Creating"}</> : "Create"}
    secondaryButtonContent="Cancel"
    primaryButtonDisabled={submitDisabled}
    onClose={() => {
      setName("");
      onClose();
    }}
    onSubmit={async () => {
      if (!submitDisabled) {
        setCreating(true);
        const id = uuid();
        transact(
          Asset.create({ id, name }, { after: (key, id) => [
            ...workspace.link(key, id),
            ...project.link(key, id),
          ]}),
          category.link("asset", id),
          category.insert_items("asset", [ id ], (category.lists.asset || []).length, { after: (key, id) => [
            ...workspace.link(key, id),
            ...project.link(key, id),
          ]}),
        ).finally(() => {
          setCreating(false);
          onClose();
        });
      }
    }}
  >
    {content}
    <div className="min-h-[10px]" />
  </Modal>

}
