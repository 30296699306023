"use client";

import React, { useEffect, useState } from "react";
import { Modal } from "./Modal";
import { LoadingIcon } from "@palette.tools/react.icons";
import { TypographyMedium, TypographySmall, TypographyXSmall } from "../typography";
import { Input } from "../shadcn/components/ui/input";
import { InviteTicket, Workspace, useAuth, usePermissions } from "@palette.tools/model.client";
import { isValidEmail } from "@palette.tools/utils";
import { Button } from "../shadcn/components/ui/button";
import { cancel_invite } from "@palette.tools/api.client";



export const WorkspaceInviteModal: React.FC<{
  open: boolean,
  onClose: () => void,
  onInviteEmail?: (email: string) => Promise<void>,
}> = ({
  open,
  onClose,
  onInviteEmail,
}) => {

  const { profile, isLoading: isLoadingProfile } = useAuth();
  const workspace = Workspace.useInContext();
  const { canEditWorkspace } = usePermissions({ profile, workspace });
  const [ invites ] = InviteTicket.useMany((workspace?.links.ticket__invite || []).map(x => x.id));

  const now = (new Date()).getTime();
  const thirtyDays = 1000 * 60 * 60 * 24 * 30;
  const duplicateEmails: Set<string> = new Set<string>();
  const filteredInvites = invites.filter(x => {
    const shouldFilter = x.data.email
      && !!x.data.created_at
      && now - x.data.created_at < thirtyDays
      && !(workspace?.links.profile || []).some(y => y.data.email === x.data.email)
      && !(duplicateEmails?.has(x.data.email));
    duplicateEmails.add(x.data.email || "");
    return shouldFilter;
  }).sort((a, b) => (a.data.created_at || 0) - (b.data.created_at || 0));

  // Regular hooks
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [userAlreadyHere, setUserAlreadyHere] = useState(false);
  const [userAlreadyInvited, setUserAlreadyInvited] = useState(false);
  const [isInviting, setInviting] = useState(false);
  const [isCancelling, setIsCancelling] = useState<Record<string, boolean>>({});

  useEffect(() => {
    setValidEmail(isValidEmail(email));
    if ((workspace?.links.profile || []).some(x => x.data.email === email)) {
      setUserAlreadyHere(true);
    }
    else {
      setUserAlreadyHere(false);
    }
    if ((filteredInvites.some(x => x.data.email === email))) {
      setUserAlreadyInvited(true);
    }
    else {
      setUserAlreadyInvited(false);
    }
  }, [email]);

  const submitDisabled = (
    !validEmail
    || userAlreadyHere
    || userAlreadyInvited
    || isLoadingProfile
  );

  let content: React.ReactNode;
  if (isLoadingProfile) {
    content = <div className="w-[24px] h-[24px]"><LoadingIcon /></div>;
  }
  else if (profile) {
    if (!canEditWorkspace) {
      content = <TypographyMedium className="italic">Sorry, you are not allowed to invite users here!</TypographyMedium>
    }
    else {
      content = <div className="flex flex-col gap-y-2">
        <div className="w-full flex flex-row gap-x-2">
          <Input
            className="flex-1 min-w-0"
            autoFocus
            name="name"
            type="text"
            placeholder="Email address..."
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            variant="cta"
            disabled={submitDisabled}
            onClick={async () => {
              if (!submitDisabled) {
                setInviting(true);
                if (onInviteEmail) {
                  onInviteEmail(email).finally(() => {
                    setInviting(false);
                  });
                }
              }
            }
          }>{isInviting ? <><div className="w-[16px] h-[16px]"><LoadingIcon /></div>{" Inviting"}</> : "Invite"}</Button>

        </div>
        <TypographyXSmall className="text-destructive min-h-[20px]">{
          email && !validEmail
          ? "Invalid email"
          : userAlreadyHere
            ? "User is already in this workspace"
            : userAlreadyInvited
              ? "User is already invited"
              : ""}
        </TypographyXSmall>
        <div className="flex flex-col overflow-y-scroll h-[300px] gap-y-2">
          {filteredInvites.map(invite =>
            <div className="flex flex-row content-center items-center">
              <TypographySmall className="max-w-[50px]">{invite.data.email}</TypographySmall>
              <div className="flex-1" />
              <Button variant="outline" className="border-destructive text-destructive hover:bg-destructive" onClick={async () => {
                const allInvitesWithEmail = invites.filter(x => x.data.email === invite.data.email);
                setIsCancelling({ ...isCancelling, [invite.data.email || ""]: true });
                await Promise.allSettled(allInvitesWithEmail.map(x => cancel_invite(x.id))).finally(() => {
                  setIsCancelling({ ...isCancelling, [invite.data.email || ""]: false });
                });
              }}>{invite.data.email && isCancelling[invite.data.email] ? <div className="h-[12px] w-[12px]"><LoadingIcon />{" "}</div> : ""}Cancel Invitation</Button>
            </div>)}
        </div>
      </div>
    }
  }
  else {
    content = <TypographyMedium className="text-destructive">An error occurred!</TypographyMedium>
  }


  return <Modal
    open={open}
    header={"Invite to Workspace"}
    description={""}
    primaryButtonClass="hidden"
    secondaryButtonClass="hidden"
    onClose={() => {
      setEmail("");
      onClose();
    }}
  >
    {content}
    <div className="min-h-[10px]" />
  </Modal>

}
