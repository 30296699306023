"use client";

import React, { useEffect } from "react";

import {
    ColumnDef,
    ColumnFiltersState,
    Row,
    SortingState,
    VisibilityState,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import {
    ExpandedState,
} from "@tanstack/table-core";

import { ArrowDownIcon, ArrowUpIcon, ChevronRightIcon, CopyIcon, FolderEditIcon, GripVerticalIcon, MoreHorizontalIcon, Trash2Icon } from "lucide-react";

import {
    Button, ContextMenuItem, ContextMenuSeparator, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger, ElementType, Progress, TypographyH4, TypographyMedium, cn,
} from "@palette.tools/react";

import { calculateTaskProgress } from "@palette.tools/model";
import { Asset, AssetGroup, Category, Project, Workspace, getPermissions, transact, useAuth, usePermissions } from "@palette.tools/model.client";
import { EventWrappedTransactionChunk } from "@palette.tools/model.core";
import ImageFallback from "../image/ImageFallback";
import TextFill from "../typography/TextFill";
import EditableTable from "./EditableTable";
import getCustomOrderedGroupedRowModel, { CustomOrderedGroupsOptions, UNGROUPED_GROUP_ID } from "./getCustomOrderedGroupedRowModel";


interface AssetRowNameCell {
  label: string,
  thumbnailStorageURL: string,
}

interface AssetRow {
  id: string,
  name: AssetRowNameCell,
  completion: number,
}

function convertAssetToTableModel(asset: Asset): AssetRow {

  const tasks = asset?.links.task || [];

  const completion = calculateTaskProgress(tasks);

  return {
    id: asset.id,
    name: {
      label: asset.data.name || "",
      thumbnailStorageURL: asset?.data.thumbnail_url || "",
    },
    completion: completion,
  }
}

function convertGroupToTableModel(group: AssetGroup) {
  return {
    id: group.id,
    label: group.data.name || "",
    orderedKeys: (group.lists.asset || []).map(x => x.id),
  }
}

export const AssetTable: React.FC<{
  workspace: Workspace | null,
  project: Project | null,
  category: Category | null,
  onClickAsset?: (asset: Asset) => void,
  onClickCreateAsset?: () => void,
  onClickDuplicateAsset?: (asset: Asset) => void,
  onClickRenameAsset?: (asset: Asset) => void,
  onClickDeleteAsset?: (asset: Asset) => void,
  onClickCreateGroup?: () => void,
  onClickRenameGroup?: (group: AssetGroup) => void,
  onClickDeleteGroup?: (group: AssetGroup) => void,
}> = ({
  workspace,
  project,
  category,
  onClickAsset,
  onClickDuplicateAsset,
  onClickRenameAsset,
  onClickDeleteAsset,
  onClickRenameGroup,
  onClickDeleteGroup,
}) => {

  const { profile } = useAuth();
  const { canEditCategory } = usePermissions({ workspace, project, category });

  const [sorting, setSorting] = React.useState<SortingState>([])

  // Get project dropdown menu
  const getRowDropdownMenu = (
    asset: Asset,
  ) => {

    const { canEditAsset, canDeleteAsset } = getPermissions({ profile, workspace, project, category, asset });

    let items: React.ReactNode[] = [];
    if (!asset) return items;

    if (canEditAsset) {
      items.push(<DropdownMenuItem
        key="duplicate"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClickDuplicateAsset && onClickDuplicateAsset(asset);
        }}
      >
        <CopyIcon width={16} height={16} />&nbsp;&nbsp;Duplicate
      </DropdownMenuItem>)
    }
    if (canEditAsset) {
      items.push(<DropdownMenuItem
        key="rename"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClickRenameAsset && onClickRenameAsset(asset);
        }}
      >
        <FolderEditIcon width={16} height={16} />&nbsp;&nbsp;Rename
      </DropdownMenuItem>)
      items.push(<DropdownMenuSeparator key="separator1" />)
    }
    if (canDeleteAsset) {
      items.push(<DropdownMenuItem
        key="delete"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClickDeleteAsset && onClickDeleteAsset(asset);
        }}
      >
        <Trash2Icon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Delete</span>
      </DropdownMenuItem>)
    }
    if (items.length > 0) return items;
  }


  // Get project context menu
  const getRowContextMenu = (
    row: Row<AssetRow>,
  ) => {

    const asset = category?.links.asset?.find(x => x.id === row.original.id);
    if (!asset) return undefined;

    let items: React.ReactNode[] = [];

    const { canEditAsset, canDeleteAsset } = getPermissions({ profile, workspace, project, category, asset });

    if (canEditAsset) {
      items.push(<ContextMenuItem
        key="duplicate_"
        onSelect={() => onClickDuplicateAsset && onClickDuplicateAsset(asset)}
      >
        <CopyIcon width={16} height={16} />&nbsp;&nbsp;Duplicate
      </ContextMenuItem>)
    }
    if (canEditAsset) {
      items.push(<ContextMenuItem
        key="rename_"
        onSelect={() => onClickRenameAsset && onClickRenameAsset(asset)}
      >
        <FolderEditIcon width={16} height={16} />&nbsp;&nbsp;Rename
      </ContextMenuItem>)
      items.push(<ContextMenuSeparator key="separator1" />)
    }
    if (canDeleteAsset) {
      items.push(<ContextMenuItem
        key="delete_"
        onSelect={() => onClickDeleteAsset && onClickDeleteAsset(asset)}
      >
        <Trash2Icon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Delete</span>
      </ContextMenuItem>)
    }
    if (items.length > 0) return items;
  }


  const columns: ColumnDef<AssetRow>[] = [

    {
      accessorKey: "move",
      header: ({ column }) => {
        return <></>
      },
      cell: ({ row }) => {

        if (canEditCategory) {
          return <div
            data-hidehandle={sorting.length > 0}
            className="opacity-100 data-[hidehandle=true]:opacity-0"
            onClick={e => {e.preventDefault(); e.stopPropagation()}}
          >
            <GripVerticalIcon
              className="opacity-0 group-hover:opacity-100"
            />
          </div>
        }
        return <></>

      },
    },

    {
      accessorKey: "name",
      header: ({ column }) => {
        const sortDirection = column.getIsSorted();
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting()}>
          Name&nbsp;{
            sortDirection ?
              sortDirection === "asc"
                ? <ArrowUpIcon className="ml-2 h-4 w-4 stroke-primary" />
                : <ArrowDownIcon className="ml-2 h-4 w-4 stroke-primary" />
              : <div className="ml-2 h-4 w-4" />
          }
        </Button>
        )
      },
      cell: ({ row }) => {
        const name: AssetRowNameCell = row.getValue("name") || {
          label: "",
          thumbnailStorageURL: "",
        }
        return <div className="flex flex-row gap-x-8 items-center">
          <ImageFallback
            className="rounded-md"
            src={name.thumbnailStorageURL}
            width="110"
            height="70"
            alt={`Thumbnail for ${row.getValue("name")}`}
          >
            <div className="h-full w-full bg-muted/80 flex items-center place-content-center rounded-md">
              <div className="w-1/3 h-1/3 flex items-center place-content-center text-muted-foreground">
                <TextFill>{name.label}</TextFill>
              </div>
            </div>
          </ImageFallback>
          <TypographyMedium className="max-w-[150px] line-clamp-2 text-ellipsis">{name.label}</TypographyMedium>
        </div>
      },
      sortingFn: (
        rowA,
        rowB,
        columnId
      ) => {
        const nameA: AssetRowNameCell = rowA.getValue("name");
        const nameB: AssetRowNameCell = rowB.getValue("name");
        return (nameA?.label || "").localeCompare(nameB?.label);
      }
    },

    {
      accessorKey: "completion",
      header: ({ column }) => {
        const sortDirection = column.getIsSorted();
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting()}>
            Tasks Complete&nbsp;{
              sortDirection ?
                sortDirection === "asc"
                  ? <ArrowUpIcon className="ml-2 h-4 w-4 stroke-primary" />
                  : <ArrowDownIcon className="ml-2 h-4 w-4 stroke-primary" />
                : <div className="ml-2 h-4 w-4" />
            }
          </Button>
        )
      },
      cell: ({ row }) => {
        const progress = (row.getValue("completion") as number || 0) * 100;

        return <div className="relative w-[130px] h-[44px]">
          <Progress
            indicatorClassName="absolute inset-0 bg-green-500"
            className="w-full h-full rounded-md"
            value={progress}
          />

          {/* Label div */}
          <div className="absolute inset-0 flex items-center justify-center">
            <TypographyH4>{Math.floor(progress)}%</TypographyH4>
          </div>
          </div>
        },
    },

    {
      accessorKey: "arrow",
      header: ({ column }) => {
        return <></>
      },
      cell: ({ row }) => {

        const asset = category?.links.asset?.find(x => x.id === row.original.id);
        if (!asset) return undefined;

        const { canEditAsset } = getPermissions({ profile, workspace, project, category, asset });

        const actionButton = <Button
          onClick={(e) => {e.stopPropagation(); e.preventDefault()}}
          variant="ghost"
          size="icon"
          className="w-[35px] h-[35px]">
            <>{canEditAsset ? <MoreHorizontalIcon size={24} className="hidden group-hover:inline-block"/> : undefined}
            <ChevronRightIcon size={24} className={cn(canEditAsset ? "inline-block group-hover:hidden" : "")} />
            </>
        </Button>

        const dropdownMenuContent = asset ? getRowDropdownMenu(asset) : undefined;

        const actionButtonMenuWrapped = dropdownMenuContent
          ? <DropdownMenu>
              <DropdownMenuTrigger asChild>{actionButton}</DropdownMenuTrigger>
              <DropdownMenuContent>{dropdownMenuContent}</DropdownMenuContent>
            </DropdownMenu>
          : actionButton;

        return actionButtonMenuWrapped;

      },
    },

  ];

  // Pull groups from server.
  const serverGroups: CustomOrderedGroupsOptions<AssetRow> = {
    uniqueKeyColumnId: "id",
    showUngroupedEvenIfEmpty: true,
    groups: (category?.lists.group__asset || []).map(convertGroupToTableModel),
  };
  const localGroups = serverGroups;

  //console.log({ localGroups });

  // Optimistically set local groups. This is because the server sometimes doesn't refresh.
  const setLocalGroups: React.Dispatch<React.SetStateAction<CustomOrderedGroupsOptions<AssetRow>>> = (value) => {
    if (typeof value === "function") value = value(localGroups);
    //_setLocalGroups(value);
    updateServerGroups(value);
  }

  // Push groups to server.
  function updateServerGroups(value: CustomOrderedGroupsOptions<AssetRow>) {
    if (!workspace || !project || !category) return;

    //console.log("Setting groups to ", value);

    const txs: EventWrappedTransactionChunk[] = [];

    value.groups.forEach((localGroup) => {


      const groupEntity = (category.lists.group__asset || []).find(x => x.id === localGroup.id);
      //console.log("iterating localGroup", { localGroup, category, groupEntity });
      if (!groupEntity || groupEntity.lists.asset === null) return;
      const serverGroup = convertGroupToTableModel(groupEntity);

      //console.log("Order among groups: ", { localGroup, serverGroup });

      if (JSON.stringify(localGroup.orderedKeys) !== JSON.stringify(serverGroup.orderedKeys)) {

        const reorders = groupEntity.reorder_items("asset", localGroup.orderedKeys, { after: (key, id) => [
          ...workspace.link(key, id),
          ...project.link(key, id),
        ] });
        const newKeys = localGroup.orderedKeys.filter(x => !serverGroup.orderedKeys.includes(x));
        const deletedKeys = serverGroup.orderedKeys.filter(x => !localGroup.orderedKeys.includes(x));

        txs.push(
          ...newKeys.flatMap(x => groupEntity.link("asset", x)),
          ...reorders,
          ...groupEntity.remove_items("asset", deletedKeys),
          ...deletedKeys.flatMap(x => groupEntity.unlink("asset", x)),
        )
      }

    });

    const localOrder = value.groups.map(x => x.id);
    const serverOrder = serverGroups.groups.map(x => x.id);
    if (JSON.stringify(localOrder) !== JSON.stringify(serverOrder)) {
      //console.log("Order among all groups is different from server: ", { localOrder, serverOrder })
      txs.push(
        ...category.reorder_items("group__asset", localOrder, { after: (key, id) => [
          ...workspace.link(key, id),
          ...project.link(key, id),
        ] }),
      )
    }

    if (txs) transact(txs);

  }

  const serverData = (category?.lists.asset || []).map(convertAssetToTableModel);
  //console.log({ serverData });
  const localData = React.useMemo(() => serverData, [JSON.stringify(serverData), JSON.stringify(serverGroups)]);
  //const localData = (category?.lists.asset || []).map(convertAssetToTableModel);

  // Push asset order to server.
  const setLocalData: React.Dispatch<React.SetStateAction<AssetRow[]>> = (value) => {
    if (typeof value === "function") value = value(localData);
    if (!workspace || !project || !category) return;

    if (JSON.stringify(value.map(x => x.id)) !== JSON.stringify(localData.map(x => x.id))) {
      //console.log("Asset order is different from server: ", { local: value.map(x => x.id), server: localData.map(x => x.id), category: (category?.lists.asset || []) })
    }

    transact(category.reorder_items("asset", value.map(x => x.id), { after: (key, id) => [
      ...workspace.link(key, id),
      ...project.link(key, id),
    ] }));
  }

  const [expanded, setExpanded] = React.useState<ExpandedState>({[UNGROUPED_GROUP_ID]: true});

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = React.useState({})
  const table = useReactTable({
    data: localData,
    columns,
    enableSortingRemoval: true,
    enableGrouping: true,
    enableExpanding: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getCustomOrderedGroupedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    onExpandedChange: setExpanded,
    manualGrouping: false,
    groupedColumnMode: false,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      expanded,
      grouping: [JSON.stringify(localGroups)],
    },
  })

  const expandedFirstTime = React.useRef<Set<string>>(new Set());
  useEffect(() => {
    localGroups.groups.forEach(({id}) => {
      if (!(expandedFirstTime.current.has(id))) {
        setExpanded(prev => {
          if (typeof prev === "boolean") return true;
          return {...prev, [id]: true}
        });
        expandedFirstTime.current.add(id);
      }
    });
  }, [table, JSON.stringify(localGroups)]);

  return <div className="flex flex-col flex-1 min-h-0 h-full gap-y-2 max-w-[920px] min-w-[770px]">
    <EditableTable
      className="flex-1"
      rowClassName="group h-min"
      orderable={canEditCategory && sorting.length < 1}
      selectionVariant="none"
      table={table}
      localData={localData}
      setLocalData={setLocalData}
      localGroups={localGroups}
      setLocalGroups={setLocalGroups}
      onClickRow={(row) => {
        if (!onClickAsset || !row.original.id) return;
        const asset = category?.links.asset?.find(x => x.id === row.original.id) || null;
        asset && onClickAsset(asset);
      }}
      getRowContextMenu={getRowContextMenu}
      getGroupRowDropdownMenuItems={(groupId) => {
        const items: ElementType<typeof DropdownMenuItem>[] = [];
        if (!canEditCategory) return items;
        const group = category?.links.group__asset?.find(x => x.id === groupId);

        items.push(
          <DropdownMenuItem key="rename" onSelect={() => { group && onClickRenameGroup?.(group)}}>
            <FolderEditIcon width={16} height={16} />&nbsp;&nbsp;Rename
          </DropdownMenuItem>
        );
        items.push(
          <DropdownMenuItem key="delete" onSelect={() => { group && onClickDeleteGroup?.(group) }}>
            <Trash2Icon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Delete</span>
          </DropdownMenuItem>
        );
        return items;
      }}
    />
    {/* Debugging /*}
    {/*
    <div className="h-[1px]">
      <pre>{JSON.stringify({
      localGroups,
      serverGroups,
      lists: category?.serialize()['group__asset']?.map((x: any) => ({ name: x.name, list: x['list_item__asset__in__group__asset']?.map((y: any) => y['asset']) })),
      links: category?.serialize()['group__asset']?.map((x: any) => ({ name: x.name, list: x['asset'] }))
    }, null, 2)}</pre>
    </div>
    */}
  </div>

}
