"use client";

import React, { useEffect, useState } from "react";
import { Modal } from "./Modal";
import { LoadingIcon } from "@palette.tools/react.icons";
import { TypographyMedium } from "../typography";
import { Input } from "../shadcn/components/ui/input";
import { Asset, Category, Project, Workspace, transact, useAuth, usePermissions } from "@palette.tools/model.client";


export const RenameAssetModal: React.FC<{
  open: boolean,
  onClose: () => void,
  workspace: Workspace | null,
  project: Project | null,
  category: Category | null,
  asset: Asset | null,
}> = ({
  open,
  onClose,
  workspace,
  project,
  category,
  asset,
}) => {

  const { profile, isLoading: isLoadingProfile } = useAuth();

  // Permissions hooks.
  const { canEditAsset } = usePermissions({ workspace, project, category, asset });

  // Regular hooks
  const [name, setName] = useState(asset?.data.name || "");
  const [isCreating, setUpdating] = useState(false);

  useEffect(() => {
    if (asset && asset.data.name) {
      setName(asset.data.name);
    }
  }, [asset]);

  let content: React.ReactNode;
  if (isLoadingProfile) {
    content = <div className="w-[24px] h-[24px]"><LoadingIcon /></div>;
  }
  else if (profile) {
    if (!canEditAsset) {
      content = <TypographyMedium className="italic">Sorry, you are not allowed to create categories under this project</TypographyMedium>
    }
    else {
      content = <div className="flex flex-row w-full items-start place-content-start py-3">
        <div className="flex flex-row gap-x-4 items-center">
          <div className="flex flex-col gap-y-2">
            <Input
              className="w-[300px]"
              autoFocus
              defaultValue={name}
              name="name"
              type="text"
              placeholder="Name of item..."
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
      </div>
    }
  }
  else {
    content = <TypographyMedium className="text-destructive">An error occurred!</TypographyMedium>
  }

  const submitDisabled = (
    !name.trim()
    || isLoadingProfile
    || !asset
    || !canEditAsset
  );

  return <Modal
    open={open}
    header={"Rename Item"}
    description={""}
    primaryButtonContent={isCreating ? <><div className="w-[16px] h-[16px]"><LoadingIcon /></div>{" Updating"}</> : "Update"}
    secondaryButtonContent="Cancel"
    primaryButtonDisabled={submitDisabled}
    onClose={() => {
      setName("");
      onClose();
    }}
    onSubmit={async () => {
      if (!submitDisabled) {
        setUpdating(true);
        transact(
          asset.update({ name }),
        ).finally(() => {
          setUpdating(false);
          onClose();
        });
      }
    }}
  >
    {content}
    <div className="min-h-[10px]" />
  </Modal>

}
