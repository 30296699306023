"use client";

import React, { useEffect, useState } from "react";
import { Modal } from "./Modal";
import { LoadingIcon } from "@palette.tools/react.icons";
import { TypographyMedium } from "../typography";
import { Input } from "../shadcn/components/ui/input";

import { AssetGroup, Category, Project, Workspace, transact, useAuth, usePermissions } from "@palette.tools/model.client";


export const RenameAssetGroupModal: React.FC<{
  open: boolean,
  onClose: () => void,
  workspace: Workspace | null,
  project: Project | null,
  category: Category | null,
  assetGroup: AssetGroup | null,
}> = ({
  open,
  onClose,
  workspace,
  project,
  category,
  assetGroup,
}) => {

  const { profile, isLoading: isLoadingProfile } = useAuth();

  // Permissions hooks.
  const { canEditCategory } = usePermissions({ workspace, project, category });

  // Regular hooks
  const [name, setName] = useState(assetGroup?.data.name || "");
  const [isUpdating, setUpdating] = useState(false);

  useEffect(() => {
    setName(assetGroup?.data.name || "");
  }, [assetGroup]);

  let content: React.ReactNode;
  if (isLoadingProfile) {
    content = <div className="w-[24px] h-[24px]"><LoadingIcon /></div>;
  }
  else if (profile) {
    if (!canEditCategory) {
      content = <TypographyMedium className="italic">Sorry, you are not allowed to rename groups under this category</TypographyMedium>
    }
    else {
      content = <div className="flex flex-row w-full items-start place-content-start py-3">
        <Input
          className="w-[300px]"
          autoFocus
          defaultValue={name}
          name="name"
          type="text"
          placeholder="'Sequence 001', 'Main Characters', etc..."
          onChange={(e) => setName(e.target.value)}
        />
      </div>
    }
  }
  else {
    content = <TypographyMedium className="text-destructive">An error occurred!</TypographyMedium>
  }

  const submitDisabled = (
    !name.trim()
    || isLoadingProfile
    || !category
    || !assetGroup
    || !canEditCategory
  );

  return <Modal
    open={open}
    header={"Rename Group"}
    description={"Set the label for this group."}
    primaryButtonContent={isUpdating ? <><div className="w-[16px] h-[16px]"><LoadingIcon /></div>{" Updating"}</> : "Update"}
    secondaryButtonContent="Cancel"
    primaryButtonDisabled={submitDisabled}
    onClose={() => {
      setName("");
      onClose?.();
    }}
    onSubmit={async () => {
      if (!submitDisabled) {
        setUpdating(true);
        transact(
          assetGroup.update({ name }),
        ).finally(() => {
          setUpdating(false);
          onClose();
        });
      }
    }}
  >
    {content}
    <div className="min-h-[10px]" />
  </Modal>

}
