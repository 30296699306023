"use client";

import React from "react";
import { DropdownMenu, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger, DropdownMenuContent } from "../shadcn/components/ui/dropdown-menu";
import { LogOutIcon, MoreHorizontalIcon, SettingsIcon, Trash2Icon } from "lucide-react";
import { ContextMenu, ContextMenuContent, ContextMenuItem, ContextMenuSeparator, ContextMenuTrigger } from "../shadcn/components/ui/context-menu";
import ImageFallback from "../image/ImageFallback";
import { Project, Workspace, getPermissions, useAuth } from "@palette.tools/model.client";


export const ProjectsCardList: React.FC<{
  workspaces: Workspace[],
  projects: Project[],
  onClickProject?: (workspace: Workspace, project: Project) => void,
  onClickProjectSettings?: (workspace: Workspace, project: Project) => void,
  onClickDeleteProject?: (workspace: Workspace, project: Project) => void,
  onClickLeaveProject?: (workspace: Workspace, project: Project) => void,
}> = ({
  workspaces,
  projects,
  onClickProject,
  onClickProjectSettings,
  onClickDeleteProject,
  onClickLeaveProject,
}) => {

  const { profile } = useAuth();

  const workspaceByProjectId: Record<string, Workspace> = projects.reduce((acc, x, i) => {
    for (let workspace of workspaces) {
      if (((workspace?.links.project || []).map(x => x.id) || []).includes(x.id)) {
        acc[x.id] = workspace;
      }
    }
    return acc;
  }, {} as Record<string, Workspace>);
  const sortedProjects = [...projects].sort((a, b) => (a.data.name || "").localeCompare(b.data.name || ""));


  // Get project dropdown menu
  const getProjectDropdownMenuContent = (
    workspace: Workspace,
    project: Project,
  ) => {
    let items: React.ReactNode[] = [];

    if (!profile) return items;

    const { canEditProject, canDeleteProject } = getPermissions({ profile, workspace, project});

    if (canEditProject) {
      items.push(<DropdownMenuItem
        key="settings"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClickProjectSettings && onClickProjectSettings(workspace, project)
        }}
      >
        <SettingsIcon width={16} height={16} />&nbsp;&nbsp;Settings
      </DropdownMenuItem>)
      items.push(<DropdownMenuSeparator key="separator1" />)
    }

    if ((project.links.profile || []).find(x => x.id == profile.id)) {
      items.push(<DropdownMenuItem
        key="leave"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClickLeaveProject?.(workspace, project)}
        }>
        <LogOutIcon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Leave</span>
      </DropdownMenuItem>)
    }

    if (canDeleteProject) {
      items.push(<DropdownMenuItem
        key="delete"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClickDeleteProject && onClickDeleteProject(workspace, project)
        }}
      >
        <Trash2Icon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Delete</span>
      </DropdownMenuItem>)
    }
    if (items.length > 0) return <DropdownMenuContent>{items}</DropdownMenuContent>;
  }


  // Get project context menu
  const getProjectContextMenuContent = (
    workspace: Workspace,
    project: Project,
  ) => {
    let items: React.ReactNode[] = [];

    if (!profile) return;

    const { canEditProject, canDeleteProject } = getPermissions({ profile, workspace, project});

    if (canEditProject) {
      items.push(<ContextMenuItem
          key="settings"
          onSelect={() => onClickProjectSettings && onClickProjectSettings(workspace, project)}
        >
        <SettingsIcon width={16} height={16} />&nbsp;&nbsp;Settings
      </ContextMenuItem>)
      items.push(<ContextMenuSeparator key="separator1" />)
    }

    if ((project.links.profile || []).find(x => x.id == profile.id)) {
      items.push(<ContextMenuItem
        key="leave"
        onClick={() => onClickLeaveProject?.(workspace, project)}>
        <LogOutIcon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Leave</span>
      </ContextMenuItem>)
    }

    if (canDeleteProject) {
      items.push(<ContextMenuItem
        key="delete"
        onSelect={() => onClickDeleteProject && onClickDeleteProject(workspace, project)}
      >
        <Trash2Icon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Delete</span>
      </ContextMenuItem>)
    }

    if (items.length > 0) return <ContextMenuContent>{items}</ContextMenuContent>;

  }

  return <div className="flex flex-row flex-wrap gap-x-[4px] gap-y-[4px]">

    {sortedProjects.map(project => {

      const workspace = workspaceByProjectId[project.id];
      if (!workspace) return null;

      const contextMenuContent = getProjectContextMenuContent(workspace, project);
      const dropdownMenuContent = getProjectDropdownMenuContent(workspace, project);

      const card = <div
        key={project.id}
        onClick={() => onClickProject && onClickProject(workspace, project)}
        className="flex flex-col rounded-xl p-[12px] border-transparent cursor-pointer select-none hover:bg-muted group"
      >
        <div className="relative">
          {dropdownMenuContent && <DropdownMenu>
            <DropdownMenuTrigger
              className="absolute bottom-[8px] right-[8px]"
              onClick={(e) => { e.preventDefault(); e.stopPropagation() }}
            >
              <div
              className="absolute bottom-[8px] right-[8px] z-10 w-[30px] h-[30px] bg-accent/50 flex items-center place-content-center rounded-md hover:bg-primary opacity-0 group-hover:opacity-100">
                <MoreHorizontalIcon />
              </div>
            </DropdownMenuTrigger>
            {dropdownMenuContent}
            </DropdownMenu>}
          <ImageFallback
            className="rounded-xl pointer-events-none"
            src={project.data.thumbnail_url}
            height="113"
            width="200"
            alt={`Thumbnail for ${project.data.name}`}
          />
        </div>
        <div className="min-h-[16px]" />
        <span className="text-foreground text-base">{project.data.name}</span>
        <span className="text-muted-foreground text-sm">{workspace.data.name}</span>
      </div>;

      if (contextMenuContent) {
        return <ContextMenu key={`context_menu_${project.id}`}>
          <ContextMenuTrigger>
            {card}
          </ContextMenuTrigger>
          {contextMenuContent}
        </ContextMenu>
      }

      return card;
    })}
  </div>
}
