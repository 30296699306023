"use client";

import React, { useEffect, useState } from "react";
import { Modal } from "./Modal";
import { LoadingIcon } from "@palette.tools/react.icons";


export const BlockingModal: React.FC<{
  onCancel?: () => void,
  children?: string | undefined | null,
  delay?: number,
}> = ({
  onCancel,
  children,
  delay = 0,
}) => {

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, delay);
  }, []);

  return <Modal
    open={open}
    containerClassName="w-full flex flex-row content-center items-center gap-x-2"
    primaryButtonClass="bg-destructive hover:bg-destructive/50 text-destructive-foreground"
    primaryButtonContent={onCancel ? "Cancel" : undefined}
    closeDisabled
    onCancel={onCancel}
    onClose={() => {}}
  >
    <div className="flex-1" />
    <div className="h-[16px] w-[16px]"><LoadingIcon /></div>
    {children}
    <div className="flex-1" />
  </Modal>

}
