// Avatar.tsx
"use client";

import React from "react";

import ImageFallback from "./ImageFallback";
import { ImgProps } from "./props";
import { UserProfile } from "@palette.tools/model.client";
import { UserIcon } from "lucide-react";
import { cn } from "../shadcn/lib/utils";
import { getColorById, getForegroundColorById } from "@palette.tools/utils";
import TextFill from "../typography/TextFill";
import { getProfileName } from "@palette.tools/model";

type AvatarProps = ImgProps & {
  user?: UserProfile,
  size?: number,
};

export const Avatar = React.forwardRef<HTMLDivElement, AvatarProps>((props, ref) => {
  const { user, size = 40, ...restProps } = props;

  let fallback: string | React.ReactNode;
  if (!user) {
    fallback = (
      <div className="w-full h-full bg-muted flex items-center place-content-center">
        <UserIcon className="w-1/2 h-1/2" />
      </div>
    );
  } else {
    fallback = (
      <div
        className="w-full h-full flex items-center place-content-center rounded-full"
        style={{ backgroundColor: getColorById(user.id) }}
      >
        <div className="w-3/4 h-3/4 flex items-center place-content-center" style={{ color: getForegroundColorById(user.id) }}>
          <TextFill>
            {getProfileName(user)?.trim().length ? getProfileName(user).trim()[0] : "?"}
          </TextFill>
        </div>
      </div>
    );
  }

  return (
    <ImageFallback
      ref={ref}
      src={user?.data.image_url}
      fallback={fallback}
      width={size}
      height={size}
      className={cn(restProps.className, "rounded-full", restProps.onClick && "cursor-pointer")}
      alt={`Profile picture of ${getProfileName(user)}`}
      {...restProps}
    />
  );
});
