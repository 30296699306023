"use client";

import * as React from "react"

import {
  HeaderGroup,
  Row,
  Table as Table_Tanstack,
  flexRender,
} from "@tanstack/react-table"

import {
  Button,
  ContextMenu,
  ContextMenuContent,
  ContextMenuTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  ElementType,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  cn,
} from "@palette.tools/react"

import { CustomOrderedGroupsOptions, UNGROUPED_GROUP_ID } from "./getCustomOrderedGroupedRowModel";
import { ChevronDownIcon, ChevronRightIcon, MoreHorizontalIcon } from "lucide-react";

type TargetItem<T> = Row<T> | HeaderGroup<T>;

function isHeaderGroup<T>(item: TargetItem<T>): item is HeaderGroup<T> {
  return (item as HeaderGroup<T>).headers !== undefined;
}

function isTargetItemEqual<T>(a: TargetItem<T> | null | undefined, b: TargetItem<T> | null | undefined) {
  if (a === undefined && b === undefined) {
    return true;
  }
  if (a === null && b === null) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  if (isHeaderGroup(a) && isHeaderGroup(b)) {
    return a.id === b.id;
  }
  if (!isHeaderGroup(a) && !isHeaderGroup(b)) {
    return a.id === b.id;
  }
  return false;
}

type DraggedItem<T> = {
  isGroup: boolean;
  id: string;
  originalItem: TargetItem<T> | undefined;
  targetItem: TargetItem<T> | undefined;
  originalX: number;
  originalY: number;
  started: boolean;
};

// Base interface without localGroups and setLocalGroups
interface EditableTableProps_Base<T> {
  table: Table_Tanstack<T>,
  localData: T[],
  setLocalData: React.Dispatch<React.SetStateAction<T[]>>,
  className?: string,
  rowClassName?: string,
  orderable?: boolean,
  selectionVariant?: "single" | "multi" | "none",
  rowVariant?: "square" | "buttonlike",
  onClickRow?: (row: Row<T>) => void,
  getRowContextMenu?: (row: Row<T>) => React.ReactNode | undefined,
  getGroupRowDropdownMenuItems?: (groupId: string) => ElementType<typeof DropdownMenuItem>[],
  loadingContent?: React.ReactNode,
  belowTableContent?: React.ReactNode,
}

// Extension for when localGroups and setLocalGroups are undefined
interface EditableTableProps_NoGroups<T> extends EditableTableProps_Base<T> {
  localGroups?: undefined,
  setLocalGroups?: undefined,
}

// Extension for when localGroups and setLocalGroups are required
interface EditableTableProps_WithGroups<T> extends EditableTableProps_Base<T> {
  localGroups: CustomOrderedGroupsOptions<T>,
  setLocalGroups: React.Dispatch<React.SetStateAction<CustomOrderedGroupsOptions<T>>>,
}

type EditableTableProps<T> = EditableTableProps_NoGroups<T> | EditableTableProps_WithGroups<T>;

export default function EditableTable<T>(props: EditableTableProps<T>) {

  const isMac =
    typeof window !== "undefined"
      ? navigator.userAgent.toUpperCase().indexOf("MAC") >= 0
      : false;
  const tableRef = React.useRef<HTMLTableElement | null>(null);
  const [draggedItem, setDraggedItem] = React.useState<DraggedItem<T> | null>(null);
  const [lastSelectedIndex, setLastSelectedIndex] = React.useState<number | null>(null);

  const rows = props.table.getGroupedRowModel().rows.flatMap(x => x.getIsGrouped() ? [x, ...x.subRows] : x);


  const handleDragStart = (
    event: React.DragEvent,
    id: string,
    originalItem: TargetItem<T>,
  ) => {

    if (!props.orderable) {
      return;
    }

    // Clear previous selections and select the row from which the drag started
    const row = rows.find(x => x.id === id);
    if (!row) return;

    if (!row.getIsSelected() || row.getIsGrouped()) {
      props.table.toggleAllRowsSelected(false);
    }

    const newDraggedItem = {
      isGroup: row.getIsGrouped(),
      id,
      originalItem,
      targetItem: row,
      originalX: event.clientX, // Store initial x coordinate
      originalY: event.clientY, // Store initial y coordinate
      started: true,
    };

    setDraggedItem(newDraggedItem);

    // Create a custom element for the drag image
    const customDragImage = document.createElement("div");
    const draggedIndices = getDraggedItems(newDraggedItem);
    customDragImage.textContent = `${draggedIndices.length} item${draggedIndices.length > 1 ? 's' : ''}`;
    customDragImage.className = cn(
      "position-absolute top-[-9999px] left-[-9999px] bg-background border-border rounded-sm ps-5"
    )

    // Append it to a hidden area in the DOM
    const hiddenDragImages = document.getElementById("hidden-drag-images");
    if (hiddenDragImages) {
      hiddenDragImages.appendChild(customDragImage);
    }

    // Set the custom drag image
    event.dataTransfer.setDragImage(customDragImage, 0, 0);

    // Attach a dragend event listener to the document
    const currentTarget = event.currentTarget as HTMLElement;
    const handleDragEnd = () => {
      setDraggedItem(null);

      if (hiddenDragImages && customDragImage.parentNode === hiddenDragImages) {
        hiddenDragImages.removeChild(customDragImage);
      }
      // Detach the event listener

      currentTarget.removeEventListener('dragend', handleDragEnd);
    };

    currentTarget.addEventListener('dragend', handleDragEnd);
  };

  function getIndex(item: TargetItem<T>) {
    if (isHeaderGroup(item)) {
      return -1;
    }
    return rows.findIndex(x => x.id === item.id);
  }

  function getGroupStartIndex(item: TargetItem<T>) {
    if (isHeaderGroup(item)) return -1;
    if (item.getIsGrouped()) return getIndex(item);
    const index = getIndex(item);
    if (index === -1) {
      return -1;
    }
    return rows.slice(0, index).findLastIndex(row => row.getIsGrouped());
  }

  function getGroupEndIndex(item: TargetItem<T>) {
    if (isHeaderGroup(item)) return -1;
    const index = getIndex(item);
    if (index === -1) {
      return -1;
    }
    const nextGroupIndex = getNextGroupIndex(index);
    return nextGroupIndex === -1 ? rows.length - 1 : nextGroupIndex - 1;
  }

  function getNextGroupIndex(index: number) {
    const nextGroupIndex = rows.findIndex((row, i) => i > index && row.getIsGrouped());
    return nextGroupIndex === -1 ? rows.length : nextGroupIndex;
  }

  function getGroupId(item: TargetItem<T>) {
    if (isHeaderGroup(item)) {
      return props.localGroups?.groups.at(0)?.id;
    }
    if (item.getIsGrouped()) {
      return item.id;
    }
    const groupStartIndex = getGroupStartIndex(item);
    return rows.find(row =>
      row.getIsGrouped() && groupStartIndex <= getIndex(row)
      && getIndex(row) <= groupStartIndex
    )?.id;
  }

  const handleDrop = async () => {
    if (
      props.table.getState().sorting.length > 0 ||
      !draggedItem ||
      !draggedItem.started ||
      draggedItem.targetItem === undefined
    ) {
      setDraggedItem(null);
      return;
    }

    // Clone the rows
    const newRows = [...rows];

    // Hold the original indices of the items being dragged
    const originalDraggedItems = getDraggedItems();

    // Separate rows and groups.
    const userDroppedRows = rows.filter(row => originalDraggedItems.find(x => isTargetItemEqual(x, row)) && !row.getIsGrouped());
    const userDroppedGroups = rows.filter(row => originalDraggedItems.find(x => isTargetItemEqual(x, row)) && row.getIsGrouped());

    // Find the targets of the drop.
    const targetItem = draggedItem.targetItem;
    const targetGroupId = getGroupId(targetItem);

    // Users are considered to be dropping groups if they are dropping groups and not rows.
    // If they are dropping both groups and rows, we'll just consider it a row drop.
    const isDroppingGroups = userDroppedGroups.length > 0 && userDroppedRows.length === 0;

    let droppedItems: Row<T>[] = [];

    // If users are dropping groups, then include the rows in the groups.
    if (isDroppingGroups) {
      userDroppedGroups.forEach(group => {
        const groupIndex = getIndex(group);
        const nextGroupIndex = getNextGroupIndex(groupIndex);
        droppedItems.push(...newRows.slice(groupIndex, nextGroupIndex));
      });
    }
    else {
      droppedItems = userDroppedRows;
    }

    if (
      !targetItem // No target item
      || (isDroppingGroups && !targetGroupId) // No target group, while moving a group (shouldn't happen)
      || !droppedItems.length // No dragged items
      || droppedItems.some(x => x.id === UNGROUPED_GROUP_ID) // Dragging the ungrouped group (not allowed)
    ){
      console.warn("Invalid drop");
      setDraggedItem(null);
      return;
    };

    const draggedIndices = new Set(droppedItems.map(x => getIndex(x)));

    // Remove the dragged items from their original positions
    const originalTargetIndex = getIndex(targetItem) + 1;
    let targetIndex = originalTargetIndex;
    const removedItems = [];
    for (let i = newRows.length - 1; i >= 0; i--) {
      if (draggedIndices.has(i)) {
        if (i < originalTargetIndex) {
          targetIndex--;
        }
        removedItems.unshift(newRows.splice(i, 1)[0]);
      }
    }

    if (isDroppingGroups) {

      // If the target group is the ungrouped group, insert the items before it.
      if (targetGroupId === UNGROUPED_GROUP_ID) {
        targetIndex = newRows.findIndex(row => row.id === UNGROUPED_GROUP_ID);
      }

      // Otherwise, targetIndex is actually the end of the group, which is dilineated by the start of the next group.
      else {
        targetIndex = newRows.findIndex((row, index) => index >= targetIndex && row.getIsGrouped());

        // No ungrouped in the table? Then insert at end.
        if (targetIndex === -1) {
          targetIndex = newRows.length;
        }
      }

    }

    // Insert the removed items at the adjusted target index
    newRows.splice(targetIndex, 0, ...removedItems);

    // Reorder groups based on rows.
    if (props.localGroups !== undefined && props.setLocalGroups !== undefined) {
      let currGroupId: string = "";
      let groupStartIndices: Record<string, number> = {};
      let groupEndIndices: Record<string, number> = {};
      let newGroups: CustomOrderedGroupsOptions<T> = {
        ...props.localGroups,
        groups: [],
      };

      newRows.forEach((row, i) => {
        if (row.getIsGrouped()) {
          groupStartIndices[row.id] = i;
          if (currGroupId !== "") {
            groupEndIndices[currGroupId] = i - 1;
          }
          currGroupId = row.id;
          if (row.id !== UNGROUPED_GROUP_ID)
            newGroups.groups.push({
              id: row.id,
              label: row.groupingValue as string,
              orderedKeys: [],
            });
        }
      });

      if (currGroupId !== "" && newRows.length) {
        groupEndIndices[currGroupId] = newRows.length - 1;
      }

      // Reassign group membership based on rows.
      newRows.forEach((row, i) => {
        if (row.getIsGrouped()) return;
        const key = row.original[props.localGroups.uniqueKeyColumnId] as string;
        if (key === undefined) return;
        for (let group of newGroups.groups) {
          if (group.id === UNGROUPED_GROUP_ID) continue;
          if (
            groupStartIndices[group.id] <= i &&
            i <= groupEndIndices[group.id]
          ) {
            group.orderedKeys.push(key);
            break;
          }
        }
      });
      props.setLocalGroups(newGroups);
    }

    const newData = newRows
      .filter((x) => !x.getIsGrouped())
      .map((x) => x.original);
    props.setLocalData(newData);

    // Deselect all rows
    props.table.toggleAllRowsSelected(false);

    // Reset dragged item and hover states
    setDraggedItem(null);
  };

  const handleDragOver = (event: React.MouseEvent, id: string, targetItem: TargetItem<T>) => {

    if (!draggedItem) return;

    event.preventDefault();

    // Update originalIndex for the dragged item
    setDraggedItem({
      ...draggedItem,
      targetItem,
    });

  };


  const handleDragLeave = (event: React.MouseEvent, id: string, targetItem: TargetItem<T>) => {

    if (!draggedItem) return;

    event.preventDefault();

    // Update originalIndex for the dragged item
    setDraggedItem((prevDraggedItem: DraggedItem<T> | null) => {
      if (!prevDraggedItem || isTargetItemEqual(prevDraggedItem?.targetItem, targetItem))
        return null;
      return {...prevDraggedItem, targetItem};
    });

  };


  // Helper function to implement shift-click behavior
  const handleShiftClick = (index: number) => {
    if (lastSelectedIndex === null) return;

    const start = Math.min(lastSelectedIndex, index);
    const end = Math.max(lastSelectedIndex, index);

    rows.slice(start, end + 1).forEach(row => {
      (!row.getIsSelected()) && row.toggleSelected(true);
    });
  };

  const getSelectedItems = () => {
    return rows.filter(x => x.getIsSelected()) as TargetItem<T>[];
  }

  const getDraggedItems = (existingDraggedItem?: DraggedItem<T>) => {
    if (!draggedItem && !existingDraggedItem) {
      return [];
    }
    const resolvedDraggedItem = draggedItem || existingDraggedItem;
    if (!resolvedDraggedItem) {
      return [];
    }

    // Handle groups.
    if (resolvedDraggedItem.isGroup) {
      return [resolvedDraggedItem.originalItem];
    }

    const selectedItems = getSelectedItems();
    if (
      selectedItems.length > 1
      && resolvedDraggedItem
      && resolvedDraggedItem.originalItem
      && selectedItems.find(x => isTargetItemEqual(x, resolvedDraggedItem.originalItem))) {
      return selectedItems;
    }

    if (!resolvedDraggedItem.originalItem) {
      return [];
    }
    return [resolvedDraggedItem.originalItem];
  }

  const containerStyle = cn(
    // Base style
    "rounded-md border overflow-auto flex flex-col",

    // Button-like variant
    props.rowVariant === "buttonlike"
      ? "border-transparent"
      : "border-border",

    // User overrides
    props.className,
  )

  const headerStyle = cn(
    // Base state
    "select-none border-b border-border",

    // Button-like variant
    props.rowVariant === "buttonlike" ? "border-x border-x-border border-t border-t-border" : "",

    // Drag top of table state
    "data-[dragtop=true]:border-b data-[dragtop=true]:border-b-primary",

    // No drop state
    "data-[nodrop=true]:border-b-border",

  )

  const rowStyle = cn(
    // Base state
    "w-full select-none transition-colors border-border",

    // Button-like variant
    props.rowVariant === "buttonlike"
      ? "rounded-tr-xl border-b-transparent data-[dragged=true]:bg-black active:bg-black"
      : "",

    // Hover state
    "hover:bg-muted/50",

    // Draggable state
    "draggable:cursor-grabbing",

    // Clickable state
    "data-[clickable=true]:cursor-pointer",

    // Selected state
    "data-[state=selected]:bg-muted",

    // Drag below state
    "data-[dragover=true]:border-b data-[dragover=true]:border-primary",

    // No Drag Allowed state
    "data-[nodrop=true]:border-border",

    // Custom user class
    props.rowClassName,
  )

  const cellStyle = "px-2 m-0";

  const dragbelow = (
    draggedItem?.started
    && !draggedItem.isGroup
    && draggedItem.targetItem
    && getIndex(draggedItem.targetItem) === rows.length - 1
  );

  const isStyledAsDragover = (targetItem: TargetItem<T>) => {

    // Not dragging over.
    if (!draggedItem?.started || !draggedItem.targetItem) return false;

    // Row dragovers are simple.
    if (!draggedItem.isGroup) return isTargetItemEqual(draggedItem.targetItem, targetItem);

    // Group dragovers more complex.

    // Rule out any rows that aren't the last row of their group.
    const index = getIndex(targetItem);
    const groupStartIndex = getGroupStartIndex(targetItem);
    const groupEndIndex = getGroupEndIndex(targetItem);
    if (
      index !== groupEndIndex
      || index === -1
      || groupStartIndex === -1
      || groupEndIndex === -1
    ) return false;

    // If dragged index is within this group, then highlight the last row.
    const draggedIndex = getIndex(draggedItem.targetItem);
    if (draggedIndex >= groupStartIndex && draggedIndex <= groupEndIndex) return true;

    // The row immediately before "Ungrouped" is special.
    // If the dragged index is after it, then highlight it.
    const nextGroupIndex = getNextGroupIndex(groupEndIndex);
    return (
      nextGroupIndex < rows.length
      && rows[nextGroupIndex].id === UNGROUPED_GROUP_ID
      && draggedIndex >= nextGroupIndex
    )

  }

  return (
    <>
      <div id="hidden-drag-images" style={{ position: "absolute", top: "-9999px", left: "-9999px" }}></div>
      <div className={containerStyle}>
        <table
          ref={tableRef}
          data-dragbelow={dragbelow}
          className={'w-full data-[dragbelow=true]:border-b data-[dragbelow=true]:border-primary min-h-0'}
        >
          <TableHeader className="bg-background sticky top-0 w-full z-10">
            {props.table.getHeaderGroups().map(headerGroup => (
              <TableRow
                key={headerGroup.id}
                data-dragtop={draggedItem?.started && isTargetItemEqual(draggedItem?.targetItem, headerGroup)}
                data-nodrop={!draggedItem?.isGroup && props.localGroups?.groups.length}
                onDrop={handleDrop}
                onDragOver={(event) => handleDragOver(event, headerGroup.id, headerGroup)}
                onDragLeave={(event) => handleDragLeave(event, headerGroup.id, headerGroup)}
                className={headerStyle}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className={cellStyle}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className="overflow-y-auto w-full">
            {props.table.getRowModel().rows?.length ? (
              props.table.getRowModel().rows.map((row, index) => {

                const contextMenu = props.getRowContextMenu && !row.getIsGrouped() ? props.getRowContextMenu(row) : undefined;

                const dropdownMenuItems = row.id !== UNGROUPED_GROUP_ID
                  ? props.getGroupRowDropdownMenuItems?.(row.id) || []
                  : [];

                const tableRowContent = row.getIsGrouped()
                  ? <td
                      colSpan={props.table.getHeaderGroups().flatMap(x => x.headers).length}
                    >
                      <div className="w-full flex flex-row items-center group min-h-20">
                        {row.getIsExpanded()
                          ? <ChevronDownIcon width={16} height={16} />
                          : <ChevronRightIcon width={16} height={16} />}
                        <span className={cn(row.id === UNGROUPED_GROUP_ID ? "text-muted-foreground uppercase" : "text-foreground")}>
                          {row.groupingValue as string}
                        </span>
                        <div className="flex-1 min-w-0" />
                        {dropdownMenuItems.length > 0 ?
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button
                                className="mr-4 w-[20px] h-[20px] opacity-0 group-hover:opacity-100"
                                variant="outline"
                                size="icon"
                                onClick={(e) => {e.stopPropagation(); e.preventDefault()}}
                              >
                                <MoreHorizontalIcon />
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent onClick={(e) => {e.stopPropagation(); e.preventDefault()}}>
                              {dropdownMenuItems}
                            </DropdownMenuContent>
                          </DropdownMenu> : <></>}
                        </div>
                    </td>
                  : row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className={cellStyle}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))

                const draggable = props.orderable && props.table.getState().sorting.length < 1 && row.id !== UNGROUPED_GROUP_ID;
                const dragged = draggedItem?.started && isTargetItemEqual(draggedItem?.originalItem, row);
                const dragover = isStyledAsDragover(row);
                const state = row.getIsSelected() && "selected";

                const tableRow = <TableRow
                  key={row.id}
                  className={rowStyle}

                  draggable={draggable}
                  data-clickable={!!props.onClickRow}
                  data-dragbelow={dragbelow}
                  data-dragged={dragged}
                  data-dragover={dragover}
                  data-state={state}

                  onDragStart={(event) => handleDragStart(event, row.id, row)}
                  onDragOver={(event) => handleDragOver(event, row.id, row)}
                  onDragLeave={(event) => handleDragLeave(event, row.id, row)}
                  onDrop={handleDrop}

                  onClick={(event) => {

                    // If the row represents a group header, expand/collapse it.
                    if (row?.getIsGrouped()) return row.toggleExpanded();

                    // Implement the file system-like selection behavior here
                    if (props.selectionVariant === "multi" && event.shiftKey) {
                      handleShiftClick(index);
                    } else if (props.selectionVariant === "multi" &&(event.metaKey && isMac || event.ctrlKey && !isMac)) {
                      row.toggleSelected();
                    } else if (props.selectionVariant !== "none") {
                      props.table.toggleAllRowsSelected(false);
                      row.toggleSelected(true);
                    }
                    setLastSelectedIndex(index); // Remember the last selected row
                    if (props.onClickRow) {
                      props.onClickRow(row);
                    }
                  }}
                >
                  {tableRowContent}
                </TableRow>

                return contextMenu
                  ? <ContextMenu key={row.id}>
                    <ContextMenuTrigger asChild>{tableRow}</ContextMenuTrigger>
                    <ContextMenuContent>{contextMenu}</ContextMenuContent>
                  </ContextMenu>
                  : tableRow

              })
            ) : (
              <></>
            )}

          </TableBody>
        </table>
        {props.belowTableContent}
        <div className="flex-1" />
      </div>
    </>
  )
}
