"use client";

import { Comment, transact, useAuth } from "@palette.tools/model.client";
import { LoadingIcon } from "@palette.tools/react.icons";
import React, { useEffect, useState } from "react";
import { Textarea } from "../shadcn/components/ui/textarea";
import { TypographyMedium } from "../typography";
import { Modal } from "./Modal";


export const EditCommentModal: React.FC<{
  open: boolean,
  onClose: () => void,
  enabled: boolean,
  comment?: Comment,
}> = ({
  open,
  onClose,
  enabled,
  comment,
}) => {

  const { profile } = useAuth();

  // Regular hooks
  const [message, setMessage] = useState(comment?.data.message || "");
  const [isUpdating, setUpdating] = useState(false);

  useEffect(() => {
    setMessage(comment?.data.message || "");
  }, [comment])

  let content: React.ReactNode;

  if (profile) {
    if (!enabled) {
      content = <TypographyMedium className="italic">Sorry, you are not allowed to edit this comment</TypographyMedium>
    }
    else {
      content = <Textarea
        className="w-full resize-none"
        autoFocus
        name="name"
        placeholder="Message.."
        defaultValue={message}
        onChange={(e) => setMessage(e.target.value)}
      />
    }
  }
  else {
    content = <TypographyMedium className="text-destructive">An error occurred!</TypographyMedium>
  }

  const submitDisabled = (
    !message.trim()
    || message.trim() === comment?.data.message?.trim()
    || !comment
    || !profile
    || !enabled
  );

  return <Modal
    open={open}
    header={"Edit Comment"}
    description={""}
    primaryButtonContent={isUpdating ? <><div className="w-[16px] h-[16px]"><LoadingIcon /></div>{" Updating"}</> : "Update"}
    secondaryButtonContent="Cancel"
    primaryButtonDisabled={submitDisabled}
    onClose={() => {
      setMessage("");
      onClose();
    }}
    onSubmit={async () => {
      if (!submitDisabled) {
        setUpdating(true);
        transact(
          comment.update({ message, edited: true }),
        ).finally(() => {
          setUpdating(false);
          onClose();
        });

      }
    }}
  >
    {content}
    <div className="min-h-[10px]" />
  </Modal>

}
