"use client";

import React from "react";

import { useNextProps } from "../utils/next/nextjs";

import { cn } from "../shadcn/lib/utils";

import { ImgProps } from "./props";

import { isReactNode } from "../utils";

type ImageFallbackProps = {
  fallback?: "placeholder" | "none" | string | React.ReactNode;
  cover?: "contain" | "cover" | undefined;
} & ImgProps;

const ImageFallback = React.forwardRef<HTMLDivElement, ImageFallbackProps>((props, ref) => {
  const { fallback = "placeholder", onLoad, ...restProps } = props;

  const [showFallback, setShowFallback] = React.useState(true);
  const { getImgProps } = useNextProps();
  const { src, className, width, height, alt } = getImgProps({ ...restProps });

  React.useEffect(() => {

    async function load() {
      await new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', resolve);
        image.addEventListener('error', reject);
        image.src = src;
      });
    }

    setShowFallback(true);
    load()
      .then(() => setShowFallback(false))
      .catch(() => {})

  }, [src]);


  const resolvedFallback = props.children || fallback;

  let fallbackURL: string;
  if (resolvedFallback === "placeholder") {
    fallbackURL = "/sample_images/placeholder_16x9.png";
  } else if (resolvedFallback !== "none" && typeof resolvedFallback === "string") {
    fallbackURL = resolvedFallback;
  } else {
    fallbackURL = "";
  }

  const sizeStyle = {
    width: width ? `${width}px` : "100%",
    height: height ? `${height}px` : "auto",
  };

  const bgStyle = {
    backgroundImage: fallbackURL ? `url(${src}), url(${fallbackURL})` : `url(${src})`,
    backgroundSize: props.cover || "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  if (typeof resolvedFallback !== "string" && isReactNode(resolvedFallback)) {
    return (
      <div
        ref={ref}
        role="img"
        aria-label={alt}
        className={cn("overflow-hidden", className)}
        onClick={props.onClick}
        style={{ ...sizeStyle, ...props.style }}
      >
        <div className="relative" style={sizeStyle}>
          {showFallback ? <div className="absolute inset-0">{resolvedFallback}</div> : undefined}
          <div className="absolute inset-0" style={bgStyle} />
        </div>
      </div>
    );
  }

  return (
    <div
      ref={ref}
      role="img"
      aria-label={alt}
      className={cn(className)}
      onClick={props.onClick}
      style={{
        ...bgStyle,
        ...sizeStyle,
        ...props.style,
      }}
    />
  );
});

export default ImageFallback;
