"use client";

import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistoryChange } from './historyChange';


const MAX_HISTORY_ITEMS = 1000; // Set a maximum number of history items to store

// Global state to track all active (mounted) keys
const activeKeys: string[] = [];


// The context that will hold our history keys
const IsolatedHistoryContext = createContext<{
  keys: string[],
  history: string[],
}>({
  keys: [],
  history: [],
});

interface IsolatedHistoryProviderProps {
  keyName: string;
  recordIf?: (pathname: string) => boolean;
  children: React.ReactNode;
}


export const IsolatedHistoryProvider: React.FC<IsolatedHistoryProviderProps> = ({ keyName, recordIf, children }) => {
  const { keys: parentKeys } = useContext(IsolatedHistoryContext);
  const currentKeys = [...parentKeys, keyName].join('/');
  const [history, setHistory] = useState<string[]>([]);

  const { addListener, removeListener } = useHistoryChange();

  useEffect(() => {
    // Check for duplicate keys upon mounting
    if (activeKeys.includes(currentKeys)) {
      console.error(`Duplicate keyName "${currentKeys}" found in IsolatedHistoryProvider`);
    } else {
      activeKeys.push(currentKeys);
    }

    // Cleanup upon unmounting
    return () => {
      const index = activeKeys.indexOf(currentKeys);
      if (index > -1) {
        activeKeys.splice(index, 1);
      }
    };
  }, [currentKeys]);

  const nextKeys = useMemo(() => [...parentKeys, keyName], [JSON.stringify(parentKeys), keyName]);

  // Listen for URL changes to update the history stack
  const handlePopState = useCallback(() => {
    const location = window.location.toString();
    
    // Use a filter to determine whether to record.
    if (recordIf && !recordIf(location)) {
      return;
    }

    let storedHistory = JSON.parse(sessionStorage.getItem(JSON.stringify(nextKeys)) || '[]') as string[];

    // Do not record if is a duplicate.
    if (storedHistory.length && storedHistory[storedHistory.length - 1] === location) {
      return;
    }
    storedHistory.push(location);

    // Prune the history if it exceeds the maximum number of items
    if (storedHistory.length > MAX_HISTORY_ITEMS) {
      storedHistory = storedHistory.slice(storedHistory.length - MAX_HISTORY_ITEMS);
    }

    sessionStorage.setItem(JSON.stringify(nextKeys), JSON.stringify(storedHistory));
  }, [nextKeys, recordIf]);

  useEffect(() => {
    handlePopState(); // Initialize with the current pathname
    window.addEventListener('popstate', handlePopState);
    addListener(handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
      removeListener(handlePopState);
    };
  }, [nextKeys, recordIf, handlePopState, addListener, removeListener]);

  useEffect(() => {
    const storedHistory = JSON.parse(sessionStorage.getItem(JSON.stringify(nextKeys)) || '[]') as string[];
    setHistory(storedHistory);
  }, [nextKeys]);

  return (
    <IsolatedHistoryContext.Provider value={{ keys: nextKeys, history }}>
      {children}
    </IsolatedHistoryContext.Provider>
  );
};


export const useIsolatedHistory = (...childKeys: string[]) => {
  const { keys: parentKeys } = useContext(IsolatedHistoryContext);
  const targetKeys = useMemo(() => JSON.stringify([...parentKeys, ...childKeys]), [JSON.stringify(parentKeys), JSON.stringify(childKeys)]);

  const back = useCallback((fallback?: () => void) => {
    const storedHistory = JSON.parse(sessionStorage.getItem(targetKeys) || '[]');
    const reversedStack = [...storedHistory].reverse();

    if (reversedStack.length > 1) {
      const stepsBack = storedHistory.length - 1 - 1;
      window.history.go(-stepsBack);
      return;
    }

    // Invoke fallback function if no matching history item is found
    if (fallback) {
      fallback();
    }
  }, [targetKeys]);

  const peekBack = useCallback((): string | null => {
    const storedHistory = JSON.parse(sessionStorage.getItem(targetKeys) || '[]');
    if (storedHistory.length > 1) {
      return storedHistory[storedHistory.length - 2];
    }
    return null;
  }, [targetKeys]);

  const current = useCallback((): string | undefined => {
    const items = JSON.parse(sessionStorage.getItem(targetKeys) || '[]') as string[];
    if (items.length) {
      return items[items.length - 1];
    }
  }, [targetKeys]);

  return { back, peekBack, current };
};
