"use client";

import React from "react";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  PrivacyPolicy,
} from "@palette.tools/react";


export const PrivacyPolicyDialog: React.FC<{children: React.ReactNode}> = ({children}) => {
  return <Dialog>
  <DialogTrigger>{children}</DialogTrigger>
  <DialogContent className="min-w-[85%]">
    <DialogHeader>
      <DialogTitle>Privacy Policy</DialogTitle>
      <DialogDescription className="p-4">
        <div className="h-[400px] w-full overflow-y-scroll">
          <PrivacyPolicy />
        </div>
      </DialogDescription>
    </DialogHeader>
  </DialogContent>
</Dialog> 

}