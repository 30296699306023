"use client";

import { formatStorage } from "@palette.tools/model";
import { Project, Workspace, getPermissions, useAuth } from "@palette.tools/model.client";
import { LogOutIcon, MoreHorizontalIcon, PlusIcon, SettingsIcon, Trash2Icon, UsersIcon } from "lucide-react";
import React from "react";
import ImageFallback from "../image/ImageFallback";
import { Button } from "../shadcn/components/ui/button";
import { ContextMenu, ContextMenuContent, ContextMenuItem, ContextMenuSeparator, ContextMenuTrigger } from "../shadcn/components/ui/context-menu";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from "../shadcn/components/ui/dropdown-menu";
import { Progress } from "../shadcn/components/ui/progress";
import { Separator } from "../shadcn/components/ui/separator";
import { Skeleton } from "../shadcn/components/ui/skeleton";
import { TypographyH3, TypographyXSmall } from "../typography";


export const WorkspaceProjectsCardList: React.FC<{
  workspaces: Workspace[],
  projects: Project[],
  onClickProject?: (workspace: Workspace, project: Project) => void,
  onClickWorkspace?: (workspace: Workspace) => void,
  onClickWorkspaceTeamPage?: (workspace: Workspace) => void,
  onClickCreateProject?: (workspace: Workspace) => void,
  onClickWorkspaceSettings?: (workspace: Workspace) => void,
  onClickDeleteWorkspace?: (workspace: Workspace) => void,
  onClickProjectSettings?: (workspace: Workspace, project: Project) => void,
  onClickDeleteProject?: (workspace: Workspace, project: Project) => void,
  onClickLeaveWorkspace?: (workspace: Workspace) => void,
  onClickLeaveProject?: (workspace: Workspace, project: Project) => void,
}> = ({
  workspaces,
  projects,
  onClickProject,
  onClickWorkspace,
  onClickWorkspaceTeamPage,
  onClickCreateProject,
  onClickWorkspaceSettings,
  onClickDeleteWorkspace,
  onClickProjectSettings,
  onClickDeleteProject,
  onClickLeaveWorkspace,
  onClickLeaveProject,
}) => {

  // Permissions hooks.
  const { profile } = useAuth();

  const workspaceByProjectId: Record<string, Workspace> = projects.reduce((acc, x, i) => {
    for (let workspace of workspaces) {
      if (((workspace.links.project || []).map(x => x.id) || []).includes(x.id)) {
        acc[x.id] = workspace;
      }
    }
    return acc;
  }, {} as Record<string, Workspace>);


  // Get workpsace dropdown menu
  const getWorkspaceDropdownMenuContent = (
    workspace: Workspace,
  ) => {
    let items: React.ReactNode[] = [];

    if (!profile) return items;

    const { canEditWorkspace, canDeleteWorkspace } = getPermissions({ profile, workspace });

    items.push(<DropdownMenuItem key="team" onSelect={() => onClickWorkspaceTeamPage?.(workspace)}>
      <UsersIcon width={16} height={16} />&nbsp;&nbsp;Team
    </DropdownMenuItem>)

    if (canEditWorkspace) {
      items.push(<DropdownMenuItem
        key="settings"
        onSelect={() => onClickWorkspaceSettings && onClickWorkspaceSettings(workspace)}>
        <SettingsIcon width={16} height={16} />&nbsp;&nbsp;Settings
      </DropdownMenuItem>)
      items.push(<DropdownMenuSeparator key="separator1" />)
    }

    if ((workspace.links.profile || []).find(x => x.id == profile.id)) {
      items.push(<DropdownMenuItem
        key="leave"
        onClick={() => onClickLeaveWorkspace?.(workspace)}>
        <LogOutIcon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Leave</span>
      </DropdownMenuItem>)
    }

    if (canDeleteWorkspace) {
      items.push(<DropdownMenuItem
        key="delete"
        onSelect={() => onClickDeleteWorkspace && onClickDeleteWorkspace(workspace)}>
        <Trash2Icon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Delete</span>
      </DropdownMenuItem>)
    }

    return items;
  }


  // Get project dropdown menu
  const getProjectDropdownMenuContent = (
    workspace: Workspace,
    project: Project,
  ) => {
    let items: React.ReactNode[] = [];

    if (!profile || !project) return items;

    const { canEditProject, canDeleteProject } = getPermissions({ profile, workspace, project });

    if (canEditProject) {
      items.push(<DropdownMenuItem
        key="settings"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClickProjectSettings && onClickProjectSettings(workspace, project)
        }}>
        <SettingsIcon width={16} height={16} />&nbsp;&nbsp;Settings
      </DropdownMenuItem>)
      items.push(<DropdownMenuSeparator key="separator1" />)
    }

    if ((project.links.profile || []).find(x => x.id == profile.id)) {
      items.push(<DropdownMenuItem
        key="leave"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClickLeaveProject?.(workspace, project)}
        }>
        <LogOutIcon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Leave</span>
      </DropdownMenuItem>)
    }

    if (canDeleteProject) {
      items.push(<DropdownMenuItem
        key="delete"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          onClickDeleteProject && onClickDeleteProject(workspace, project)
        }}>
        <Trash2Icon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Delete</span>
      </DropdownMenuItem>)
    }
    return items;
  }


  // Get project context menu
  const getProjectContextMenuContent = (
    workspace: Workspace,
    project: Project,
  ) => {
    let items: React.ReactNode[] = [];

    if (!profile) return items;

    const { canEditProject, canDeleteProject } = getPermissions({ profile, workspace, project });

    if (canEditProject) {
      items.push(<ContextMenuItem
        key="settings"
        onSelect={() => onClickProjectSettings && onClickProjectSettings(workspace, project)}>
        <SettingsIcon width={16} height={16} />&nbsp;&nbsp;Settings
      </ContextMenuItem>)
      items.push(<ContextMenuSeparator key="separator1" />)
    }

    if ((project.links.profile || []).find(x => x.id == profile.id)) {
      items.push(<ContextMenuItem
        key="leave"
        onClick={() => onClickLeaveProject?.(workspace, project)}>
        <LogOutIcon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Leave</span>
      </ContextMenuItem>)
    }

    if (canDeleteProject) {
      items.push(<ContextMenuItem
        key="delete"
        onSelect={() => onClickDeleteProject && onClickDeleteProject(workspace, project)}>
        <Trash2Icon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Delete</span>
      </ContextMenuItem>)
    }

    return items;
  }


  return <div className="flex flex-col w-full gap-y-[20px]">
    {workspaces.map(workspace => {

      const storageSize = workspace.data.storage_size || 0;
      const storageLimit = workspace.data.storage_limit || 0;
      const storagePercentage = storageLimit ? Math.round(storageSize / storageLimit * 100) : 0;

      const { canCreateProject } = getPermissions({ profile, workspace });

      const workspaceDropdownMenuContent = getWorkspaceDropdownMenuContent(workspace);

      return <div key={workspace.id} className="flex flex-col">
        <div className="flex flex-row items-center">
          <ImageFallback
            src={workspace.data.thumbnail_url}
            className="rounded-full cursor-pointer"
            height="30"
            width="30"
            alt={`Thumbnail for ${workspace.data.name}`}
            onClick={() => onClickWorkspace && onClickWorkspace(workspace)}
          />
          <div className="min-w-[18px]" />
          <TypographyH3
            onClick={() => onClickWorkspace && onClickWorkspace(workspace)}
            className="text-muted-foreground cursor-pointer hover:text-foreground"
          >{workspace.data.name}</TypographyH3>
          <div className="min-w-[18px]" />
          <Progress value={storagePercentage} className="max-w-[100px]" />
          <div className="min-w-[8px]" />
          <TypographyXSmall>{`${formatStorage(storageSize)} / ${formatStorage(storageLimit)} used`}</TypographyXSmall>
          <div className="flex-1" />
          {canCreateProject
            ? <Button
                variant="ghost"
                size="icon"
                className="hover:bg-primary"
                onClick={() => onClickCreateProject && onClickCreateProject(workspace)}>
                <PlusIcon />
              </Button>
            : undefined
          }

          {workspaceDropdownMenuContent.length ? <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon"><MoreHorizontalIcon /></Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {workspaceDropdownMenuContent}
            </DropdownMenuContent>
          </DropdownMenu> : undefined}

        </div>
        <div className="min-h-[8px]" />
        <Separator />
        <div className="min-h-[20px]" />
        <div className="flex flex-row flex-wrap gap-x-[4px]">
          {projects.filter(x => workspaceByProjectId[x.id] === workspace).sort((a, b) => (a.data.name || "").localeCompare(b.data.name || "")).map(project => {

            const contextMenuContent = getProjectContextMenuContent(workspace, project);
            const dropdownMenuContent = getProjectDropdownMenuContent(workspace, project);

            const card = <div
              key={project.id}
              onClick={() => onClickProject && onClickProject(workspace, project)}
              className="flex flex-col rounded-xl p-[12px] border-transparent gap-y-[16px] cursor-pointer select-none hover:bg-muted group"
            >
              <div className="relative">
                {dropdownMenuContent.length ? <DropdownMenu>
                    <DropdownMenuTrigger
                      className="absolute bottom-[8px] right-[8px]"
                      onClick={(e) => {e.preventDefault(); e.stopPropagation()}}
                    >
                      <div
                      className="absolute bottom-[8px] right-[8px] z-10 w-[30px] h-[30px] bg-accent/50 flex items-center place-content-center rounded-md hover:bg-primary opacity-0 group-hover:opacity-100">
                        <MoreHorizontalIcon />
                      </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      {dropdownMenuContent}
                    </DropdownMenuContent>
                  </DropdownMenu> : undefined}
                  <ImageFallback
                    className="rounded-xl pointer-events-none"
                    src={project.data.thumbnail_url}
                    height="113"
                    width="200"
                    alt={`Thumbnail for ${project.data.name}`}
                  />
              </div>
              <span className="text-foreground text-base">{project.data.name}</span>
            </div>;

            if (contextMenuContent.length) {
              return <ContextMenu key={`context_menu_${project.id}`}>
                <ContextMenuTrigger>
                  {card}
                </ContextMenuTrigger>
                <ContextMenuContent>
                  {contextMenuContent}
                </ContextMenuContent>
              </ContextMenu>
            }

            return card;

          })}
        </div>
      </div>
    })}
  </div>
}



export const EmptyWorkspaceProjectsCardList: React.FC = () => {

  return <div className="flex flex-col w-full gap-y-[20px]">
    {[1, 2, 3].map(i => {

      return <div key={i} className="flex flex-col">
        <div className="flex flex-row items-center">
          <Skeleton
            className="rounded-full pointer-events-none object-cover object-center w-[30px] h-[30px]"
          />
          <div className="min-w-[18px]" />
          <TypographyH3 className="text-muted-foreground"><Skeleton className="h-[20px] w-[200px]" /></TypographyH3>
          <div className="flex-1" />
        </div>
        <div className="min-h-[8px]" />
        <Separator />
        <div className="min-h-[20px]" />
        <div className="flex flex-row flex-wrap gap-x-[4px]">
          {[1, 2, 3].map(y => {

            const card = <div key={y} className="flex flex-col rounded-xl p-[12px] border-transparent gap-y-[16px] select-none group">
              <div className="relative">
                <Skeleton
                  className="rounded-xl pointer-events-none min-h-[113px] min-w-[200px]"
                />
              </div>
              <span className="text-foreground text-base"><Skeleton className="h-4 w-[100px]" /></span>
            </div>;

            return card;

          })}
        </div>
      </div>
    })}
  </div>
}
