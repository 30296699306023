"use client";

import React, { useEffect, useState } from "react";
import { Modal } from "./Modal";
import { LoadingIcon } from "@palette.tools/react.icons";
import { TypographyMedium } from "../typography";
import { Input } from "../shadcn/components/ui/input";
import { Label } from "../shadcn/components/ui/label";
import { Project, Workspace, useAuth, usePermissions } from "@palette.tools/model.client";
import { delete_entity } from "@palette.tools/api.client";



export const DeleteProjectModal: React.FC<{
  open: boolean,
  onClose: () => void,
}> = ({
  open,
  onClose,
}) => {

  const { profile, isLoading: isLoadingProfile } = useAuth();
  const workspace = Workspace.useInContext();
  const project = Project.useInContext();

  const { canDeleteProject } = usePermissions({ profile, workspace, project });

  // Regular hooks
  const [name, setName] = useState("");
  const [isDeleting, setDeleting] = useState(false);

  let content: React.ReactNode;
  if (isLoadingProfile) {
    content = <div className="w-[24px] h-[24px]"><LoadingIcon /></div>;
  }
  else if (profile) {
    if (!canDeleteProject) {
      content = <TypographyMedium className="italic">You are not allowed to delete this project.</TypographyMedium>
    }
    else {
      content = <>
        <TypographyMedium className="text-destructive">Type <strong>"{project?.data.name}"</strong> to confirm.</TypographyMedium>
        <div className="min-h-[10px]" />
        <Label htmlFor="name">Name</Label>
        <Input
          name="name"
          type="text"
          placeholder="Name of the project..."
          onChange={(e) => setName(e.target.value)}
        />
      </>
    }
  }
  else {
    content = <TypographyMedium className="text-destructive">An error occurred!</TypographyMedium>
  }

  const submitDisabled = (
    !canDeleteProject
    || !project
    || name !== project.data.name
    || isLoadingProfile
  );

  return <Modal
    open={open}
    header={"Permanently Delete Project"}
    description={"This CANNOT be undone!"}
    primaryButtonClass="bg-destructive hover:bg-destructive/50 text-destructive-foreground"
    primaryButtonContent={isDeleting ? <><div className="w-[16px] h-[16px]"><LoadingIcon /></div>{" Deleting"}</> : "Delete"}
    secondaryButtonContent="Cancel"
    primaryButtonDisabled={submitDisabled}
    onClose={() => {
      setName("");
      onClose();
    }}
    onSubmit={async () => {
      if (!submitDisabled && !!project) {
        setDeleting(true)
        await delete_entity('project', project.id)
          .finally(() => {
            setDeleting(false);
            onClose && onClose();
          });
      }
    }}
  >
    {content}
    <div className="min-h-[10px]" />
  </Modal>

}
