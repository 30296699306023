"use client";

import React from "react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuRadioGroup, DropdownMenuRadioItem, DropdownMenuTrigger } from "../shadcn/components/ui/dropdown-menu";
import { Button } from "../shadcn/components/ui/button";
import { WorkspaceRoleLabels, WorkspaceRoles } from "@palette.tools/model";
import { DropdownOptions } from "./options";
import { cn } from "../shadcn/lib/utils";
import { ChevronDownIcon } from "lucide-react";


export const WorkspaceRolesDropdownOptions: DropdownOptions<WorkspaceRoles> = new Map([

  [WorkspaceRoles.admin, {
    key: WorkspaceRoles.admin,
    label: WorkspaceRoleLabels[WorkspaceRoles.admin],
    className: "bg-blue-500 hover:bg-blue-700"
  }],

  [WorkspaceRoles.member, {
    key: WorkspaceRoles.member,
    label: WorkspaceRoleLabels[WorkspaceRoles.member],
    className: "bg-blue-500 hover:bg-blue-700"
  }],

]);

export const WorkspaceRolesDropdownMenu: React.FC<{
  editable?: boolean,
  selectedRole?: WorkspaceRoles,
  onSelectRole?: (selectedStatus: WorkspaceRoles) => void,
}> = ({
  editable = true,
  selectedRole = WorkspaceRoles.member,
  onSelectRole,
}) => {
  const selectedOption = WorkspaceRolesDropdownOptions.get(selectedRole) || WorkspaceRolesDropdownOptions.get(WorkspaceRoles.member);

  const button = <Button
    disabled={!editable}
    className={cn(selectedOption!.className, "disabled:opacity-100 rounded-xl w-[160px]")}
  >
    {selectedOption!.label}
    {editable ? <ChevronDownIcon className="ml-2 h-4 w-4" /> : undefined}
  </Button>

  if (!editable) return button;

  return <DropdownMenu>
    <DropdownMenuTrigger asChild>
      {button}
    </DropdownMenuTrigger>
    <DropdownMenuContent className="w-56">
      <DropdownMenuRadioGroup
        value={selectedRole?.toString()}
        onValueChange={(value: any) => onSelectRole && onSelectRole(+value as WorkspaceRoles)}
      >
        {Array.from(WorkspaceRolesDropdownOptions.entries()).map(
          ([key, value]) => <DropdownMenuRadioItem
            key={key.toString()}
            value={key.toString()}
          >{value.label}</DropdownMenuRadioItem>)
        }
      </DropdownMenuRadioGroup>
    </DropdownMenuContent>
  </DropdownMenu>

}
