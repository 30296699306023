import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import { PanelItemProvider } from "./PanelItemProvider";
import { Panel } from "./Panel";
import { PanelDivider } from "./PanelDivider";
import { PanelDirection, PanelEdges, PanelPosition } from "./PanelEdges";
import { ElementType, isElementOf } from "../../utils";
import { cn } from "../../shadcn/lib/utils";


type PanelLayoutChild = ElementType<typeof PanelLayout> | ElementType<typeof Panel>;

function isPanelLayoutChild(child: any): child is PanelLayoutChild {
  return isElementOf(PanelLayout, child) || isElementOf(Panel, child);
}

interface PanelLayoutProps {
  className?: string;
  direction: PanelDirection;
  children: PanelLayoutChild | PanelLayoutChild[];
  weight?: number;
  outerEdges?: PanelEdges;
}


export const PanelLayout: React.FC<PanelLayoutProps> = ({
  className,
  direction,
  children,
  weight,
  outerEdges = {top: true, right: true, bottom: true, left: true},
}) => {

  const allChildren = React.Children.toArray(children);
  const panelLayoutChildren = allChildren.filter((x) => isPanelLayoutChild(x));
  const panels = panelLayoutChildren.filter((x) => isElementOf(Panel, x));
  const layouts = panelLayoutChildren.filter((x) => isElementOf(PanelLayout, x));

  // Handle collapsed children
  const [collapsedPanels, setCollapsedPanels] = useState(new Array(panelLayoutChildren.length).fill(false));
  const setCollapseFunctionsRef = useRef<((state: boolean) => void)[]>([]);
  function areOtherPanelsCollapsed(currentState: boolean[], currentIndex: number) {
    return currentState
      .filter((_, idx) => idx !== currentIndex)
      .every(status => status);
  }

  const handleSetCollapsed = useCallback((index: number, collapsed: boolean) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setCollapsedPanels(prevState => {
        const updatedStates = [...prevState];
        updatedStates[index] = collapsed;

        if (collapsed) {
          const otherChildrenCollapsed = updatedStates.filter((_, idx) => idx !== index);
          if (otherChildrenCollapsed.every(status => status)) {
            const otherChildIndex = index === 0 ? 1 : index - 1;
            updatedStates[otherChildIndex] = false;
            setCollapseFunctionsRef.current[otherChildIndex](false);
          }
        }

        return updatedStates;
      });
    });
  }, [collapsedPanels]);

  const flexDirection = direction === 'horizontal' ? 'row' : 'column';

  const childrenWithDividers = useMemo(() => {
    // Set the edges of the children.
    const childrenWithProps = React.Children.map(children, (child, index) => {

      if (React.isValidElement(child) && (isElementOf(PanelLayout, child) || isElementOf(Panel, child))) {
        let childEdges = { ...outerEdges };

        // If the direction is horizontal, then for the first element, right is false,
        // and for the last element, left is false.
        if (direction === 'horizontal') {
          if (index !== 0) childEdges.left = false;
          if (index !== React.Children.count(children) - 1) childEdges.right = false;
        }
        
        // Similarly for vertical.
        else {
          if (index !== 0) childEdges.top = false;
          if (index !== React.Children.count(children) - 1) childEdges.bottom = false;
        }

        // Special consideration for panels
        if (isElementOf(Panel, child)) {
          const clonedChild = React.cloneElement(child, {
            ...child.props,
            outerEdges: childEdges,
          });

          const position: PanelPosition = (index <= (Math.ceil((panels.length + layouts.length) / 2) - 1))
            ? "start"
            : "end";

          // Don't let panels collapse if there's only one item.
          const canCollapse = panels.length + layouts.length > 1 ? undefined : false; 

          return <PanelItemProvider
            position={position}
            direction={direction}
            onRegisterSetCollapsed={setFunc => { setCollapseFunctionsRef.current[index] = setFunc }}
            canCollapse={canCollapse}
            onSetCollapsed={(collapsed) => handleSetCollapsed(index, collapsed)}>
            {clonedChild}
          </PanelItemProvider> 
        }

        return React.cloneElement(child, {
          outerEdges: childEdges,
        });
      }

      return child;
    });

    // Insert dividers.
    return childrenWithProps.reduce((acc, val, index) => {
      acc.push(val);

      // All except the very last element should have a divider.
      if (index < childrenWithProps.length - 1) {
        acc.push(<PanelDivider direction={direction} key={index} />)
      }

      return acc;
    }, [] as React.ReactElement<any>[]);

  }, [ direction, children, outerEdges, handleSetCollapsed ]);

  return (
    <div
      className={className}
      style={{
        display: 'flex',
        flexDirection,
        flex: weight,
        minHeight: 0,
      }}>
        {childrenWithDividers}
      </div>
  );
}
