"use client";

import { Crop } from "@palette.tools/model";
import { storage } from "./storage";

import {
    ref as storageRef,
    UploadTaskSnapshot,
    getDownloadURL, uploadBytesResumable
} from "firebase/storage";



export interface UploadedThumbnailResult {
  url?: string | null,
  uncropped_url?: string | null,
  crop?: Crop | null,
}

export async function uploadThumbnail(
  croppedBlob: Blob,
  uncroppedBlob: Blob | null,
  crop: Crop | null,
  storagePath: string,
  onProgress?: (percentage: number) => void,
): Promise<UploadedThumbnailResult> {

  // Create an off-screen canvas and context
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const MAX_WIDTH = 300;
  const MAX_HEIGHT = 300;

  // Load the image into the canvas and resize it
  const img = new Image();
  img.crossOrigin = 'anonymous'; // This is important to avoid tainting the canvas

  const result: UploadedThumbnailResult = {};

  await new Promise<void>((resolve, reject) => {
    img.onload = async () => {
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      if (!ctx) {
        throw new Error("Couldn't shrink image");
      }
      ctx.drawImage(img, 0, 0, width, height);

      // Convert canvas to blob
      canvas.toBlob(async (resizedBlob) => {
        if (!resizedBlob) {
          reject(new Error("No resized image found"));
          return;
        }
        // Firebase storage reference
        const uploadPathCropped = `${storagePath}/cropped_thumbnail.png`;
        const storageRefCropped = storageRef(storage, uploadPathCropped);

        // Upload cropped image
        const uploadTaskCropped = uploadBytesResumable(storageRefCropped, resizedBlob);

        // Optional: Handle progress, success, and errors
        uploadTaskCropped.on('state_changed',
          (snapshot: UploadTaskSnapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes);
            onProgress?.((progress / 3) + 0.1);
          },
          error => {
            console.error('Upload error:', error);
            reject(error);
          },
          async () => {
            const croppedDownloadURL = await getDownloadURL(uploadTaskCropped.snapshot.ref);
            result.url = croppedDownloadURL;
            result.uncropped_url = undefined;
            result.crop = crop;

            // Handle uncroppedURL, if any
            if (uncroppedBlob) {
              const uploadPathOriginal = `${storagePath}/original_thumbnail.png`;
              const storageRefOriginal = storageRef(storage, uploadPathOriginal);

              // Upload original image
              const uploadTaskUncropped = uploadBytesResumable(storageRefOriginal, uncroppedBlob);
              await new Promise<void>((resolve, reject) => {

                uploadTaskUncropped.on('state_changed',
                  (snapshot: UploadTaskSnapshot) => {
                  const progress = (snapshot.bytesTransferred / snapshot.totalBytes);
                    onProgress?.((progress / 2) + 0.5);
                  },
                  error => {
                    console.error('Upload error:', error);
                    reject(error);
                  },
                  async () => {
                    const originalDownloadURL = await getDownloadURL(uploadTaskUncropped.snapshot.ref);
                    result.uncropped_url = originalDownloadURL;
                    resolve();
                  }
                );

              });
                          }
            resolve();
          }
        );

      }, 'image/png');
    };
    img.onerror = reject;
    img.src = URL.createObjectURL(croppedBlob);
  });

  return result;
}
