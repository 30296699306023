"use client";

import React from "react";
import { TypographyXSmall } from "../typography";
import { Building2Icon, PlusIcon } from "lucide-react";
import { Button } from "../shadcn/components/ui/button";
import { Skeleton } from "../shadcn/components/ui/skeleton";
import ImageFallback from "../image/ImageFallback";
import { Workspace } from "@palette.tools/model.client";


export const WorkspaceSidebarList: React.FC<{
  workspaces: Workspace[];
  selectedWorkspaceId?: string | null,
  onClickWorkspace?: (workspace: Workspace) => void,
  onClickAllWorkspaces?: () => void,
  onClickCreateWorkspace?: () => void,
}> = ({
  workspaces,
  selectedWorkspaceId = "all",
  onClickWorkspace,
  onClickAllWorkspaces,
  onClickCreateWorkspace,
}) => {

  return <div>
    <div
      onClick={onClickAllWorkspaces}
      data-selected={selectedWorkspaceId === "all"}
      className="flex flex-row px-[16px] gap-x-3 h-[55px] rounded-2xl items-center cursor-pointer hover:bg-muted active:bg-black data-[selected=true]:bg-black"
    >
      <div className="flex w-[35px] h-[35px] items-center place-content-center">
        <Building2Icon height={30} width={30} />
      </div>
      <TypographyXSmall>All Workspaces</TypographyXSmall>
      <div className="flex-1" />
      <Button
        size="icon"
        variant="ghost"
        className="w-[25px] h-[25px]"
        onClick={onClickCreateWorkspace}
      ><PlusIcon width={14} height={14} /></Button>
    </div>
    {workspaces.map(workspace => {
      return <div
          key={workspace.id}
          onClick={() => onClickWorkspace && onClickWorkspace(workspace)}
          data-selected={selectedWorkspaceId === workspace.id}
          className="flex flex-row px-[16px] gap-x-3 h-[55px] rounded-2xl items-center hover:bg-muted cursor-pointer active:bg-black data-[selected=true]:bg-black"
        >
          <ImageFallback
            className="rounded-full object-cover object-center"
            src={workspace.data.thumbnail_url}
            height="35"
            width="35"
            alt={`Workspace thumbnail for ${workspace.data.thumbnail_url}`}
          />
          <TypographyXSmall>{workspace.data.name}</TypographyXSmall>
        </div>
    })}
  </div>
}


export const EmptyWorkspaceSidebarList: React.FC = () => {

  return <div>
    {[1, 2, 3].map(i => {
      return <div
          key={i}
          className="flex flex-row px-[16px] gap-x-3 h-[55px] rounded-2xl items-center "
        >
          <Skeleton
            className="rounded-full object-cover object-center w-[35px] h-[35px] bg-muted"
          />
          <TypographyXSmall><Skeleton className="h-4 w-[100px]" /></TypographyXSmall>
        </div>
    })}
  </div>
}
