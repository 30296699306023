"use client";

import React, { createContext, useContext, useEffect, useState, FC, useCallback, useRef } from 'react';

type HistoryChangeListener = (newPath: string) => void;

const HistoryChangeContext = createContext<{
  addListener: (listener: HistoryChangeListener) => void;
  removeListener: (listener: HistoryChangeListener) => void;
}>({
  addListener: () => {},
  removeListener: () => {},
});

export const HistoryInterceptorProvider: FC<{children: React.ReactNode}> = ({ children }) => {
  const [listeners, setListeners] = useState<HistoryChangeListener[]>([]);
  const listenersRef = useRef(listeners);

  useEffect(() => {
    listenersRef.current = listeners;
  }, [listeners]);

  useEffect(() => {
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    const handleHistoryChange = () => {
      const newPath = window.location.pathname;
      listenersRef.current.forEach((listener) => listener(newPath));
    };

    window.history.pushState = (...args) => {
      originalPushState.apply(window.history, args);
      handleHistoryChange();
    };

    window.history.replaceState = (...args) => {
      originalReplaceState.apply(window.history, args);
      handleHistoryChange();
    };

    return () => {
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, []);

  const addListener = useCallback((listener: HistoryChangeListener) => {
    setListeners((prevListeners) => [...prevListeners, listener]);
  }, []);

  const removeListener = useCallback((listener: HistoryChangeListener) => {
    setListeners((prevListeners) => prevListeners.filter((l) => l !== listener));
  }, []);

  return (
    <HistoryChangeContext.Provider value={{ addListener, removeListener }}>
      {children}
    </HistoryChangeContext.Provider>
  );
};

export const useHistoryChange = () => {
  const context = useContext(HistoryChangeContext);
  if (!context) {
    throw new Error('useHistoryChange must be used within a HistoryInterceptorProvider');
  }
  return context;
};
