"use client";


import React, { useEffect, useState } from "react";
import { getBrandFromLink, getBrandLabel } from "@palette.tools/utils";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../shadcn/components/ui/tooltip";
import { getBrandIcon } from "@palette.tools/react.icons";
import { Input } from "../shadcn/components/ui/input";
import { ClipboardCheckIcon, CopyIcon, SaveIcon } from "lucide-react";
import { Button } from "../shadcn/components/ui/button";
import { OpenInNewWindowIcon } from "@palette.tools/react.icons";
import { TypographyMedium } from "../typography";
import { useToast } from "../shadcn/components/ui/use-toast";


export const LinkBox: React.FC<{
  editable?: boolean,
  initialUrl: string,
  onSave: (url: string) => void,
}> = ({
  editable = false,
  initialUrl,
  onSave,
}) => {
  const [ url, setUrl ] = useState(initialUrl);
  const brand = getBrandFromLink(url);
  const label = getBrandLabel(brand) || "External Link";
  const icon = getBrandIcon(brand, {className: "min-w-[16px] min-h-[16px] max-w-[16px] max-h-[16px]"});

  const { toast } = useToast();
  
  useEffect(() => {
    setUrl(initialUrl)
  }, [initialUrl])


  const onSubmit = () => {
    onSave && onSave(url);
    toast({
      duration: 1500,
      description: <div
        className="flex flex-row items-center place-content-center"
      >
        <SaveIcon />&nbsp;&nbsp;Saved link
      </div>,
    })
  }


  const onCopy = () => {
    navigator.clipboard.writeText(url);
    toast({
      duration: 1500,
      description: <div
        className="flex flex-row items-center place-content-center"
      >
        <ClipboardCheckIcon />&nbsp;&nbsp;Copied to clipboard
      </div>,
    })
  }

  return (
    <form onSubmit={(e) => {e.preventDefault(); e.stopPropagation(); onSubmit()}}>
      <div className="flex flex-col place-content-center items-left">
        <div className="flex flex-row place-content-left items-center gap-x-[5px] ps-[12px] py-[5px]">
            <TooltipProvider>
              <Tooltip delayDuration={300}>
                <TooltipTrigger className="cursor-default" onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>{icon}</TooltipTrigger>
                <TooltipContent>
                  {label}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <div className="min-w-[3px]" />
            {editable ? 
              <Input
                className="min-w-[100px]"
                value={url}
                onChange={e => setUrl(e.target.value)}
                placeholder={"Enter URL..."}
              /> : <div className="min-w-[100px] truncate">
                <TypographyMedium className="w-full text-muted-foreground">{url}</TypographyMedium>
              </div>
            }

            {editable ?
              <Button
                type="submit"
                variant="outline"
                disabled={!editable || !url || url === initialUrl}
                className="rounded-full w-[36px] h-[36px]"
              >
                <SaveIcon className={"min-w-[16px] min-h-[16px]"} />
              </Button> : <></>
            }

            <Button
              variant="outline"
              onClick={(e) => {e.preventDefault(); e.stopPropagation(); onCopy()}}
              disabled={!url}
              className="rounded-full w-[36px] h-[36px]"
            >
              <CopyIcon className={"min-w-[16px] min-h-[16px]"} />
            </Button>

            <Button
              variant="outline"
              disabled={!url}
              data-disabled={!url}
              className="rounded-full w-[36px] h-[36px] data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-50"
              asChild
            >
              <a href={url} target="_blank" rel="noopener noreferrer" data-disabled={!url}>
                <OpenInNewWindowIcon className={"min-w-[16px] min-h-[16px]"} />
              </a>
            </Button>

        </div>
      </div>
    </form>
  )
}
