import React from 'react';
import {
  Button,
} from "@palette.tools/react";


import { LoadingIcon } from '@palette.tools/react.icons';
import { UploadCloudIcon } from 'lucide-react';


export interface FileValidation {
  isValid: boolean,
  reason?: string,
}

interface UploadButtonProps {
  children?: React.ReactNode,
  isUploading?: boolean,
  className?: string,
  disabled?: boolean,
  onChooseFile?: (f: File) => void,
  onValidateFile?: (f: File) => FileValidation,
}


export const UploadButton: React.FC<UploadButtonProps> = ({
  children = "Upload",
  isUploading = false,
  className,
  disabled = false,
  onChooseFile,
  onValidateFile = () => ({ isValid: true }),
}) => {

  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleFileChange = async () => {
    if (!inputRef.current || !inputRef.current.files || inputRef.current.files.length < 1) {
      return;
    }
    const f = inputRef.current.files[0];
    const validation = onValidateFile(f);
    if (!validation.isValid) {
      alert(validation.reason || "File cannot be uploaded.");
    }
    else {
      onChooseFile?.(f);
    }
  }

  return (
    <div className="flex flex-col items-end gap-0.5">
      <input ref={inputRef} type="file" style={{ display: "none" }} onChange={handleFileChange}/>
      <Button
        className={className}
        type="button"
        disabled={disabled}
        onClick={() => inputRef.current?.click()}
      >{
        isUploading
        ? <div className="w-[16px] h-[16px]"><LoadingIcon /></div>
        : <UploadCloudIcon className="w-[16px] h-[16px]" />
      }&nbsp;&nbsp;{children}</Button>
    </div>
  );
};