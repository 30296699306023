import React, { useRef, useState } from "react";
import { Label } from "../../shadcn/components/ui/label";
import { Input, InputProps } from "../../shadcn/components/ui/input";
import { cn } from "../../shadcn/lib/utils";


export const ChooseFileButton: React.FC<InputProps> = ({ ...props }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  return (
    <div
      className={`overflow-hidden border border-border rounded-xl hover:bg-muted w-full ${
        isFocused ? "ring-offset-background ring-2 ring-ring" : ""
      }`}
    >
      <Label
        htmlFor="file_input"
        className="min-h-4 min-w-full select-none cursor-pointer"
      >
        <div>&nbsp;</div>
      </Label>
      <div className="flex flex-row">
        <Label
          htmlFor="file_input"
          className="min-h-4 min-w-[32px] select-none cursor-pointer"
        >
          <div>&nbsp;</div>
        </Label>
        <Input
          accept="image/*"
          {...props}
          type="file"
          className={cn("outline-0 ring-offset-transparent focus-visible:ring-transparent focus-visible:ring-0 bg-transparent cursor-pointer file:cursor-pointer file:text-foreground file:border-2 file:border-solid file:border-border file:rounded-sm border-transparent m-0 p-0", props.className)}
          aria-describedby="file_input_help"
          id="file_input"
          ref={inputRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <Label
          htmlFor="file_input"
          className="min-h-4 min-w-[32px] select-none cursor-pointer"
        >
          <div>&nbsp;</div>
        </Label>
      </div>
    </div>
  );
};