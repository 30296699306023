"use client";

import { formatStorage } from '@palette.tools/model';
import { FileEntry, UserProfile, Workspace } from '@palette.tools/model.client';
import { formatFullTimestamp } from '@palette.tools/utils';
import { PlayCircle, FileDownIcon } from 'lucide-react';
import React from 'react';
import ImageFallback from '../image/ImageFallback';
import { cn } from '../shadcn/lib/utils';
import { TypographyMedium, TypographyMuted } from '../typography';
import TextFill from '../typography/TextFill';
import { isPlayableVideo } from '../utils';
import { FileDownloadTrigger } from '../triggers/FileDownloadTrigger';
import { Button } from '../shadcn/components/ui/button';


interface FileDetailsPanelProps extends React.HTMLAttributes<HTMLDivElement> {
  workspace: Workspace | null,
  file_entry: FileEntry | null,
  previewOpen?: boolean,
  onClickPreview?: (file_entry: FileEntry) => void,
}


const FileDetail: React.FC<{ label: string, value: string }> = ({ label, value }) => {
  return <div className="flex flex-col gap-y-1 w-full break-all">
    <TypographyMuted>{label}</TypographyMuted>
    <TypographyMedium>{value}</TypographyMedium>
  </div>
}


export const FileDetailsPanel: React.FC<FileDetailsPanelProps> = React.forwardRef<HTMLDivElement, FileDetailsPanelProps>(({
  workspace,
  file_entry,
  className,
  onClickPreview,
}, ref) => {

  const users: UserProfile[] = workspace?.links.profile || [];
  const author = users.find(x => x && x.id === file_entry?.data.created_by);

  return <div ref={ref} className={cn("w-full h-full", className, "flex flex-col gap-y-4")}>
    {file_entry
      ? <>
        <div
          data-previewable={file_entry.data.mime_type?.startsWith("image")}
          className="data-[previewable]:cursor-pointer"
        >
          <ImageFallback
            data-previewable={file_entry.data.mime_type?.startsWith("image")}
            cover="contain"
            className="rounded-md data-[previewable]:cursor-pointer min-h-[113px]"
            src={file_entry.data.url}
            width="200"
            height="113"
            alt={`Preview of file ${file_entry.data.name || "Untitled"}`}
            onClick={() => onClickPreview?.(file_entry)}
          >
            <div className={cn("flex flex-col items-center place-content-center h-full w-full", isPlayableVideo(file_entry.data.mime_type) ? "border-border border-2" : "")}>
              <div className="w-1/3 h-1/3 flex items-center place-content-center text-muted-foreground">
                {isPlayableVideo(file_entry.data.mime_type)
                  ? <PlayCircle className="stroke-muted-foreground w-full h-full" />
                  : <TextFill>{"No preview available"}</TextFill>
                }

              </div>
            </div>
          </ImageFallback>
        </div>

        <FileDetail label="File name" value={file_entry.data.name || "Untitled"} />
        <FileDetail label="File type" value={file_entry.data.mime_type || "Unknown File Type"} />
        <FileDetail label="Size" value={formatStorage(file_entry.data.size || 0)} />
        {author?.data.email ? <FileDetail label="Author" value={author.data.email} /> : <></>}
        {file_entry.data.created_at ? <FileDetail label="Uploaded" value={formatFullTimestamp(file_entry.data.created_at)} /> : <></>}
        <FileDownloadTrigger file_entry={file_entry}>
          <Button className="flex flex-row gap-x-2" variant="secondary"><FileDownIcon className="w-[16px] h-[16px]" />Download</Button>
        </FileDownloadTrigger>

      </> : <><i>No file selected</i></>
    }

  </div>

});
