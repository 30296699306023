"use client";

import React, { useEffect, useState } from "react";
import { Modal } from "./Modal";
import { Progress } from "../shadcn/components/ui/progress";
import { TypographyMedium } from "../typography";


export const ProgressModal: React.FC<{
  open: boolean,
  children?: string | undefined | null,
  onAcknowledgeError?: () => void,
  onCancel?: () => void,
  progress?: number,
  error?: string,
}> = ({
  open,
  children,
  onAcknowledgeError,
  onCancel,
  progress,
  error,
}) => {

  const [errorAcknowledged, setErrorAcknowledged] = useState(false);

  useEffect(() => {
    setErrorAcknowledged(false);
  }, [error]);

  return <Modal
    open={open && !errorAcknowledged}
    containerClassName="w-full flex flex-row content-center items-center gap-x-2"
    primaryButtonClass="bg-destructive hover:bg-destructive/50 text-destructive-foreground"
    primaryButtonContent={onCancel ? "Cancel" : undefined}
    closeDisabled={!error}
    onCancel={() => { onCancel?.() }}
    onClose={() => {
      if(error) setErrorAcknowledged(true);
      onAcknowledgeError?.();
    }}
  >
    {error
      ? <TypographyMedium className="text-destructive">{error}</TypographyMedium>
      : <>
        {children}
        <div className="flex-1 ml-2"><Progress value={(progress || 0) * 100} /></div>
      </>
    }
  </Modal>

}
