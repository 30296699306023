"use client";

import React from "react";
import { cn } from "../shadcn/lib/utils";
import { TypographyMuted, TypographyXSmall } from "../typography";
import TextFill from "../typography/TextFill";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../shadcn/components/ui/tooltip";
import { getExtension, stripExtension } from "@palette.tools/utils";
import { Asset, Category, FileEntry, Project, Task, Workspace, getPermissions, useAuth } from "@palette.tools/model.client";
import { ContextMenu, ContextMenuContent, ContextMenuItem, ContextMenuTrigger } from "../shadcn/components/ui/context-menu";
import { Trash2Icon } from "lucide-react";


interface FileListProps extends React.HTMLAttributes<HTMLDivElement> {
  workspace: Workspace | null,
  project: Project | null,
  category: Category | null,
  asset: Asset | null,
  task: Task | null,
  file_entries?: FileEntry[],
  selectedFileId?: string,
  onSelectFile?: (file_entry: FileEntry) => void,
  onSelectFileDoubleClick?: (file_entry: FileEntry) => void,
  onClickDeleteFile?: (file_entry: FileEntry) => void,
}


export const FilesList: React.FC<FileListProps> = React.forwardRef<HTMLDivElement, FileListProps>(({
  workspace,
  project,
  category,
  asset,
  task,
  file_entries,
  selectedFileId,
  onSelectFile,
  onSelectFileDoubleClick,
  onClickDeleteFile,
  className,
  ...props
}, ref) => {

  const { profile } = useAuth();

  const active_files = (file_entries || []).filter(x => x.data.active);

  // Get context menu
  const getContextMenuContent = (file_entry: FileEntry) => {

    let items: React.ReactNode[] = [];

    const { canDeleteFile } = getPermissions({ profile, workspace, project, category, asset, task });

    if (canDeleteFile) {
      items.push(<ContextMenuItem
        key="delete"
        onSelect={() => onClickDeleteFile?.(file_entry)}
      >
        <Trash2Icon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Delete</span>
      </ContextMenuItem>)
    }

    if (items.length > 0) return items;

  }


  return <TooltipProvider skipDelayDuration={0} delayDuration={0} disableHoverableContent>
    <div ref={ref} className={cn(className, "flex flex-row flex-wrap p-1 gap-4")} {...props}>
      {active_files?.length ? active_files.map(x => {

        const contextMenuContent = getContextMenuContent(x);

        const content = <div
          data-selected={selectedFileId === x.id}
          className="flex flex-col items-center place-content-start gap-2 w-[100px] h-[115px] cursor-pointer p-2 rounded-md hover:ring-white hover:ring-2 data-[selected=true]:bg-zinc-700"
          key={x.id}
          onClick={() => onSelectFile?.(x)}
          onDoubleClick={() => onSelectFileDoubleClick?.(x)}
        >
          <div className="h-[60px] w-[60px] bg-muted/80 flex items-center place-content-center rounded-md ">
            <div className="w-2/3 h-2/3 flex items-center place-content-center text-muted-foreground">
              <TextFill>{getExtension(x.data.name)}</TextFill>
            </div>
          </div>
          <TypographyXSmall className="font-inter text-xs/[14px] font-regular line-clamp-2 text-ellipsis break-all">{stripExtension(x.data.name)}</TypographyXSmall>
        </div>

        const contentWrapped = contextMenuContent
          ? <>
            <ContextMenu>
              <ContextMenuTrigger>
                {content}
              </ContextMenuTrigger>
              <ContextMenuContent>
                {contextMenuContent}
              </ContextMenuContent>
            </ContextMenu></> : content;

        return <Tooltip>

          <TooltipContent>
            <div className="max-w-[300px] max-h-[300px] select-none">
              <TypographyMuted className="italic">{x.data.name}</TypographyMuted>
            </div>
          </TooltipContent>
          <TooltipTrigger>
            {contentWrapped}
          </TooltipTrigger>
        </Tooltip>
      }) : <TypographyMuted><i>No files</i></TypographyMuted>}
    </div>
  </TooltipProvider>
});
