"use client";

import React, { ReactNode, ReactElement } from 'react';


// Clone of the <Show /> conditional component from Solid JS.

type ShowProps = {
  when: boolean;
  children: React.ReactElement | null;
};

export const Show: React.FC<ShowProps> = ({ when, children }) => {
  return when ? children : null;
};


// Clone of the <Switch> and <Match> components from Solid JS.

type MatchProps = {
  when: boolean;
  children: React.ReactNode;
};

export const Match: React.FC<MatchProps> = ({ children }) => {
  return <>{children}</>;
};

type SwitchProps = {
  children: React.ReactElement<MatchProps>[];
};

export const SwitchCase: React.FC<SwitchProps> = ({ children }) => {
  let matched = false;

  return (
    <>
      {React.Children.map(children, (child) => {
        if (
          React.isValidElement<MatchProps>(child)
          && child.type === Match
          && !matched
          && child.props.when
        ) {
          matched = true;
          return child.props.children;
        }
        return null;
      })}
    </>
  );
};


// Clone of the <For> component from Solid JS.

type ForProps<T> = {
  each: T[];
  renderChild: (item: T, index: number) => React.ReactElement | null;
};

export function For<T>({ each, renderChild }: ForProps<T>): React.ReactElement | null {
  return <>{each.map((item, index) => renderChild(item, index))}</>;
}