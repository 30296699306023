import React, { ForwardedRef, MutableRefObject, ReactElement, ReactNode, Ref } from "react";
import { type ClassValue } from "clsx"
 

/**
 * A utility type to extract the element type of a React component.
 */
export type ElementType<T> = T extends React.FC<infer P> ? React.ReactElement<P, React.FC<P>> : never;


/**
 * A utility type to extract the props of a React component.
 */
export type ElementProps<T extends HTMLElement> = React.HTMLProps<T>;

/**
 * A utility type to convert all properties of an object to an array of that property type.
*/
export type ArrayifyProperties<T> = {
  [P in keyof T]: T[P][];
};

type ElementByTagName<T extends keyof HTMLElementTagNameMap> = HTMLElementTagNameMap[T];

/**
 * A utility type to convert all properties of an object to an array of that property type.
 * @param component - The component to check against.
 * @param child - The child to check.
 * @returns True if the child is a valid element of the component.
 */
export function isElementOf<A extends any, B extends React.FC<A>>(component: React.FC<A>, child?: ReactNode): child is ReactElement<A, B> {
  return React.isValidElement(child) && child.type === component;
}


/**
 * A utility type to convert all properties of an object to an array of that property type.
 * @param refs - The refs to merge.
 * @returns A function which will call all refs with the given value.
 */
export const mergeRefs = <T,>(refs: Array<Ref<T> | undefined>) => {
  return (value: T) => {
    refs.forEach(ref => {
      if (ref === undefined) return; // Skip undefined refs
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref !== null) {
        (ref as MutableRefObject<T | null>).current = value;
      }
    });
  };
};



type OverrideProp<
  T, 
  PropName extends string,
  ConditionalType = true, 
  FallbackType = any,
> = ConditionalType extends true
    ? PropName extends keyof T
      ? T // Use existing prop type
      : T & { [key in PropName]?: FallbackType } // Add React.ReactNode prop
    : ConditionalType extends false
      ? Omit<T, PropName> // Remove prop
      : ConditionalType extends undefined
        ? T // No change
        : T & { [key in PropName]?: ConditionalType }; // Specific prop type


type PassthroughComponentProps<PropsType> = OverrideProp<
  OverrideProp<
    PropsType,
    "children", true, React.ReactNode
  >, "className", true, ClassValue
>;


export function overrideElement<
  TagName extends keyof HTMLElementTagNameMap = keyof HTMLElementTagNameMap,
  CustomPropsType extends object = {},
>(
  render: (args: {
    props: PassthroughComponentProps<ElementProps<ElementByTagName<TagName>>>,
    ref: React.Ref<ElementByTagName<TagName>>,
    customProps: CustomPropsType,
    className?: string,
    children?: React.ReactNode,
  }) => React.ReactElement | null,
  customPropKeys: Array<keyof CustomPropsType> = [],
) {
  return React.forwardRef<
    ElementByTagName<TagName>,
    PassthroughComponentProps<ElementProps<ElementByTagName<TagName>>> & CustomPropsType
  >((props, ref) => {
    const { children, className, ...restProps } = props;

    // Separate the props
    // Separate the props and reconstruct the objects
    const intrinsicProps = Object.fromEntries(
      Object.entries(restProps).filter(([key]) => !customPropKeys.includes(key as keyof CustomPropsType))
    ) as PassthroughComponentProps<ElementProps<ElementByTagName<TagName>>>;

    const customProps = Object.fromEntries(
      Object.entries(restProps).filter(([key]) => customPropKeys.includes(key as keyof CustomPropsType))
    ) as CustomPropsType;


    const element = render({
      props: intrinsicProps,
      ref,
      customProps,
      className,
      children,
    });

    return element;
  });
}

// Omits the given keys from an object
function omit<T extends object, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> {
  const newObj = { ...obj };
  keys.forEach(key => delete newObj[key]);
  return newObj;
}