"use client";

import React from "react";

import { PanelEdges } from "./PanelEdges";
import { ChevronsDownIcon, ChevronsLeftIcon, ChevronsRightIcon, ChevronsUpIcon, PanelBottomCloseIcon, PanelBottomIcon, PanelLeftCloseIcon, PanelLeftIcon, PanelRightCloseIcon, PanelRightIcon, PanelTopCloseIcon, PanelTopIcon } from "lucide-react";
import { TypographyXSmall } from "../../typography";
import { cn } from "../../shadcn/lib/utils";
import { Match, SwitchCase } from "../../utils";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../shadcn/components/ui/tooltip";

interface TitleButtonProps {
  title: string;
  enhancer?: React.ReactNode;
  direction?: "horizontal" | "vertical";
  position?: "start" | "end";
  collapsible?: boolean;
  collapsed?: boolean;
  onClick?: () => void;
}

export const TitleButton: React.FC<TitleButtonProps> = ({
  title,
  enhancer,
  direction = "horizontal",
  position = "start",
  collapsible = false,
  collapsed = false,
  onClick,
}) => {

  let edges: PanelEdges = {top: false, right: false, bottom: false, left: false};

  // Set the edge.
  if (direction === "horizontal") {
    if (position === "start") {
      edges.left = true;
    }
    else {
      edges.right = true;
    }
  }
  else {
    if (position === "start") {
      edges.top = true;
    }
    else {
      edges.bottom = true;
    }
  }

  const getEnhancer = () => {

    if (enhancer) {
      return enhancer;
    } 

    if (edges.top) {
      return <PanelTopIcon height={16} width={16} className="text-foreground" />
    }
    else if (edges.right) {
      return <PanelRightIcon height={16} width={16} className="text-foreground" />
    }
    else if (edges.bottom) {
      return <PanelBottomIcon height={16} width={16} className="text-foreground" />
    }
    else { // left
      return <PanelLeftIcon height={16} width={16} className="text-foreground" />
    }
  }

  const getCollapseIcon = () => {
    if (!collapsible) {
      return <></>
    }

    if ((edges.top && !collapsed) || (edges.bottom && collapsed)) {
      return <ChevronsUpIcon height={16} width={16} className="opacity-30 group-hover:opacity-100" />
    }
    else if ((edges.right && !collapsed) || (edges.left && collapsed)) {
      return <ChevronsRightIcon height={16} width={16} className="opacity-30 group-hover:opacity-100" />
    }
    else if ((edges.bottom && !collapsed) || (edges.top && collapsed)) {
      return <ChevronsDownIcon height={16} width={16} className="opacity-30 group-hover:opacity-100" />
    }
    else if ((edges.left && !collapsed) || (edges.right && collapsed)) { // left
      return <ChevronsLeftIcon height={16} width={16} className="opacity-30 group-hover:opacity-100" />
    }
    return <></>
  }

  if (!title && !collapsible) {
    return <></>;
  }

  const button = <div
      onClick={() => collapsible && onClick && onClick()}
      className={cn(
        direction === "horizontal" && collapsed ? "flex-col" : "flex-row",
        "flex flex-gap-5 p-[10px] select-none group",
        collapsible ? "hover:bg-muted/75 rounded-md cursor-pointer" : "",
      )}
    >
    {edges.right ? getCollapseIcon() : getEnhancer()}
    <SwitchCase>
      <Match when={!!title && !collapsed}>
        <div className="w-2" />
          <TypographyXSmall>{title}</TypographyXSmall>
        <div className="w-2" />
      </Match>
      <Match when={!title || collapsed}>
        <div className="w-2" />
      </Match>
    </SwitchCase>
    {edges.right ? getEnhancer() : getCollapseIcon()}
  </div>;

  if (title && collapsed) {
    return <TooltipProvider skipDelayDuration={0} delayDuration={0} disableHoverableContent>
      <Tooltip>
        <TooltipContent>{title}</TooltipContent>
        <TooltipTrigger>
          {button}
        </TooltipTrigger>
      </Tooltip>
    </TooltipProvider>
  }

  return button;
}