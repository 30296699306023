"use client";

import React from "react";
import { Modal } from "./Modal";


export const InfoModal: React.FC<{
  open: boolean,
  onAcknowledge?: () => void,
  children?: string | undefined | null,
}> = ({
  open,
  onAcknowledge,
  children,
}) => {

  const [acknowledged, setAcknowledged] = React.useState(false);

  React.useEffect(() => {
    setAcknowledged(false);
    onAcknowledge?.();
  }, [children]);

  return <Modal
    open={open && !acknowledged}
    containerClassName="w-full flex flex-row content-center items-center gap-x-2"
    primaryButtonContent="Ok"
    onSubmit={async () => setAcknowledged(true)}
    onCancel={() => setAcknowledged(true)}
    onClose={() => setAcknowledged(true)}
  >
    <div className="flex-1" />
    {children}
    <div className="flex-1" />
  </Modal>

}
