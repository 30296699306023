"use client";

import React from "react";
import { FileEntry, downloadFile } from "@palette.tools/model.client";


interface FileDownloadTriggerProps<T extends React.ElementType> {
  children: React.ReactElement<React.ComponentPropsWithoutRef<T>>;
  file_entry: FileEntry,
}


export function FileDownloadTrigger<T extends React.ElementType>({
  children,
  file_entry,
}: FileDownloadTriggerProps<T>) {

  const props: typeof children.props = {
    ...children.props,
    onClick() {
      downloadFile(file_entry);
    }
  }

  const trigger = React.cloneElement(children, props);

  return <>{trigger}</>;

}
