"use client";

import React, { useContext } from "react";


export type getImgPropsFn = (props: any) => any;


export interface NextPropsValue {
  getImgProps: getImgPropsFn,
  setNavigationWarning: (msg?: string | null) => void,
}

const defaults: NextPropsValue = {
  getImgProps: (props: any) => props,
  setNavigationWarning: (msg?: string | null) => {},
}


export const NextPropsContext = React.createContext(defaults);
export const useNextProps = () => useContext(NextPropsContext);


export const NextPropsProvider: React.FC<{
  children: React.ReactNode,
  getImgProps?: getImgPropsFn,
  setNavigationWarning?: (msg?: string | null) => void,
}> = ({
  children,
  getImgProps = defaults.getImgProps,
  setNavigationWarning = defaults.setNavigationWarning,
}) => {

  return <NextPropsContext.Provider value={{
    getImgProps,
    setNavigationWarning,
  }}>
    {children}
  </NextPropsContext.Provider>

}