"use client";

import React, { createContext, useContext, useEffect, useState } from 'react';
import { PanelDirection, PanelPosition } from './PanelEdges';


interface PanelItemContextValue {
  collapsed: boolean,
  canCollapse: boolean | undefined,
  setCollapsed: (collapsed: boolean) => void,
  direction: PanelDirection,
  position: PanelPosition,
}


const PanelCollapsibleItemContext = createContext<PanelItemContextValue>({
  collapsed: false,
  canCollapse: undefined,
  setCollapsed: () => {},
  direction: "horizontal",
  position: "start",
});


export const PanelItemProvider: React.FC<{
  children: React.ReactNode,
  canCollapse?: boolean | undefined,
  direction: PanelDirection,
  position: PanelPosition,
  onRegisterSetCollapsed: (setCollapsedFunc: (state: boolean) => void) => void,
  onSetCollapsed?: (collapsed: boolean) => void,
}> = ({
  children,
  canCollapse,
  direction,
  position,
  onRegisterSetCollapsed,
  onSetCollapsed,
}) => {
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    onRegisterSetCollapsed(setCollapsed);
  }, [onRegisterSetCollapsed]);

  useEffect(() => {
    onSetCollapsed && onSetCollapsed(collapsed);
  }, [ collapsed ]);

  return <PanelCollapsibleItemContext.Provider value={{ collapsed, canCollapse, setCollapsed, direction, position }}>
    {children}
  </PanelCollapsibleItemContext.Provider>
};


export const usePanelItem = () => {
  return useContext(PanelCollapsibleItemContext);
};