"use client";

import React, { useState } from "react";
import { Modal } from "./Modal";
import { TypographyMedium } from "../typography";
import { ThumbnailEditor } from "../editors";
import { Crop } from "react-image-crop";
import { UploadedThumbnailResult, uploadThumbnail } from "@palette.tools/model.client/src/thumbnail";
import { ThumbnailType } from "@palette.tools/model";


export const UploadThumbnailModal: React.FC<{
  open: boolean,
  onClose: () => void,
  storage_path: string,
  url?: string,
  uncropped_url?: string,
  crop?: Crop,
  thumbnail_type?: ThumbnailType,
  editable?: boolean,
  onPreUpload?: () => Promise<void>,
  onSuccessfulUpload?: (result: UploadedThumbnailResult) => Promise<void>,
}> = ({
  open,
  onClose,
  storage_path,
  url,
  uncropped_url,
  crop,
  thumbnail_type,
  editable = false,
  onPreUpload,
  onSuccessfulUpload,
}) => {

  // Set editability via permissions.
  const [error, setError] = useState("");

  const save = async (croppedBlob: Blob, uncroppedBlob: Blob | null, crop: Crop, onSaveProgress: (progress: number) => void) => {
    onPreUpload && await onPreUpload();
    await uploadThumbnail(
      croppedBlob,
      uncroppedBlob,
      crop,
      storage_path,
      (progress: number) => onSaveProgress?.(progress),
    ).then(async (result) => {
      await onSuccessfulUpload?.(result);
      onClose();
    }).catch((error: Error) => {
      setError(error.message)
    });
  }

  // Regular hooks
  let content: React.ReactNode;
  if (!editable) {
    content = <TypographyMedium className="italic">Sorry, you are not allowed to edit this thumbnail.</TypographyMedium>
  }
  else {
    content = <>
      <ThumbnailEditor
        url={url}
        uncropped_url={uncropped_url}
        crop={crop}
        thumbnail_type={thumbnail_type}
        disabled={!editable}
        saveFn={save}
      />
      <TypographyMedium className="text-destructive">{error}</TypographyMedium>
    </>
  }

  return <Modal
    open={open}
    header={"Change Thumbnail"}
    description={"Set a displayable icon."}
    primaryButtonContent={"Done"}
    secondaryButtonClass="hidden"
    secondaryButtonContent="Cancel"
    primaryButtonClass="hidden"
    primaryButtonDisabled={!editable}
    onClose={() => {
      setError("");
      onClose?.();
    }}
    onSubmit={async () => {}}
  >
    {content}
    <div className="min-h-[10px]" />
  </Modal>

}
