"use client";

import React from "react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuLabel, DropdownMenuRadioGroup, DropdownMenuRadioItem, DropdownMenuSeparator, DropdownMenuTrigger } from "../shadcn/components/ui/dropdown-menu";
import { Button } from "../shadcn/components/ui/button";
import { TaskLabel, TaskStatus } from "@palette.tools/model";
import { DropdownOptions } from "./options";
import { cn } from "../shadcn/lib/utils";
import { ChevronDownIcon } from "lucide-react";


export const TaskStatusDropdownOptions: DropdownOptions<TaskStatus> = new Map([

  [TaskStatus.NotStarted, {
    key: TaskStatus.NotStarted,
    label: TaskLabel[TaskStatus.NotStarted],
    className: "bg-gray-500 hover:bg-gray-700"
  }],

  [TaskStatus.NeedsWork, {
    key: TaskStatus.NeedsWork,
    label: TaskLabel[TaskStatus.NeedsWork],
    className: "bg-yellow-500 hover:bg-yellow-700",
  }],

  [TaskStatus.InProgress, {
    key: TaskStatus.InProgress,
    label: TaskLabel[TaskStatus.InProgress],
    className: "bg-yellow-500 hover:bg-yellow-700",
  }],

  [TaskStatus.NeedsReview, {
    key: TaskStatus.NeedsReview,
    label: TaskLabel[TaskStatus.NeedsReview],
    className: "bg-orange-500 hover:bg-orange-700",
  }],

  [TaskStatus.Complete, {
    key: TaskStatus.Complete,
    label: TaskLabel[TaskStatus.Complete],
    className: "bg-green-500 hover:bg-green-700",
  }],

]);

export const StatusDropdownMenu: React.FC<{
  editable?: boolean,
  selectedStatus?: TaskStatus,
  onSelectStatus?: (selectedStatus: TaskStatus) => void,
}> = ({
  editable = true,
  selectedStatus = TaskStatus.NotStarted,
  onSelectStatus,
}) => {
  const selectedOption = TaskStatusDropdownOptions.get(selectedStatus) || TaskStatusDropdownOptions.get(TaskStatus.NotStarted);


  const button = <Button
    disabled={!editable}
    className={cn(selectedOption!.className, "disabled:opacity-100 rounded-xl w-[160px]")}
  >
    {selectedOption!.label}
    {editable ? <ChevronDownIcon className="ml-2 h-4 w-4" /> : undefined}
  </Button>

  if (!editable) return button;

  return <DropdownMenu>
    <DropdownMenuTrigger asChild>
      {button}
    </DropdownMenuTrigger>
    <DropdownMenuContent className="w-56">
      <DropdownMenuRadioGroup
        value={selectedStatus?.toString()}
        onValueChange={(value: any) => onSelectStatus && onSelectStatus(+value as TaskStatus)}
      >
        {Array.from(TaskStatusDropdownOptions.entries()).map(
          ([key, value]) => <DropdownMenuRadioItem
            key={key.toString()}
            value={key.toString()}
          >{value.label}</DropdownMenuRadioItem>)
        }
      </DropdownMenuRadioGroup>
    </DropdownMenuContent>
  </DropdownMenu> 

}