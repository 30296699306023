"use client";

import React, { useState } from "react";
import { Modal } from "./Modal";
import { LoadingIcon } from "@palette.tools/react.icons";
import { TypographyMedium } from "../typography";
import { Input } from "../shadcn/components/ui/input";
import { Label } from "../shadcn/components/ui/label";
import { Category, Project, Workspace, useAuth, usePermissions } from "@palette.tools/model.client";
import { delete_entity } from "@palette.tools/api.client";


export const DeleteCategoryModal: React.FC<{
  open: boolean,
  onClose: () => void,
  workspace: Workspace | null,
  project: Project | null,
  category: Category | null,
}> = ({
  open,
  onClose,
  workspace,
  project,
  category,
}) => {

  const { profile, isLoading: isLoadingProfile } = useAuth();
  const { canDeleteCategory } = usePermissions({ workspace, project, category });

  // Regular hooks
  const [name, setName] = useState("");
  const [isDeleting, setDeleting] = useState(false);

  let content: React.ReactNode;
  if (isLoadingProfile) {
    content = <div className="w-[24px] h-[24px]"><LoadingIcon /></div>;
  }
  else if (profile) {
    if (!canDeleteCategory) {
      content = <TypographyMedium className="italic">You are not allowed to delete this category.</TypographyMedium>
    }
    else {
      content = <>
        <TypographyMedium className="text-destructive">Type <strong>"{category?.data.name}"</strong> to confirm.</TypographyMedium>
        <div className="min-h-[10px]" />
        <Label htmlFor="name">Name</Label>
        <Input
          name="name"
          type="text"
          placeholder="Name of the category..."
          onChange={(e) => setName(e.target.value)}
        />
      </>
    }
  }
  else {
    content = <TypographyMedium className="text-destructive">An error occurred!</TypographyMedium>
  }

  const submitDisabled = (
    !canDeleteCategory
    || !category
    || name !== category.data.name
    || isLoadingProfile
  );

  return <Modal
    open={open}
    header={"Permanently Delete Category"}
    description={"This CANNOT be undone!"}
    primaryButtonClass="bg-destructive hover:bg-destructive/50 text-destructive-foreground"
    primaryButtonContent={isDeleting ? <><div className="w-[16px] h-[16px]"><LoadingIcon /></div>{" Deleting"}</> : "Delete"}
    secondaryButtonContent="Cancel"
    primaryButtonDisabled={submitDisabled}
    onClose={() => {
      setName("");
      onClose();
    }}
    onSubmit={async () => {
      if (!submitDisabled) {
        setDeleting(true);
        await delete_entity('category', category.id)
          .finally(() => {
            setDeleting(false);
            onClose && onClose();
          });
      }
    }}
  >
    {content}
    <div className="min-h-[10px]" />
  </Modal>

}
