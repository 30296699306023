import React, { useEffect, useRef } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../shadcn/components/ui/popover";
import { UserSelector } from "../editors";
import { UserProfile } from "@palette.tools/model.client";

export const UserPopover: React.FC<{
  disable?: boolean,
  children: React.ReactNode,
  selectableUsers?: UserProfile[],
  selectedUser?: UserProfile,
  onSelectUser?: (user: UserProfile) => void,
  onClearSelection?: () => void,
}> = ({
  disable = false,
  children,
  selectableUsers,
  onSelectUser,
  onClearSelection,
}) => {

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  if (disable) {
    return <>{children}</>;
  }

  return (
    <Popover>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent>
        <UserSelector
          selectableUsers={selectableUsers || []}
          onSelectUser={onSelectUser}
          onClearSelection={onClearSelection}
        />
      </PopoverContent>
    </Popover>
  );
};
