"use client";

import React, { useMemo } from "react";

import { CalendarCheckIcon, CalendarIcon, CalendarPlusIcon } from "lucide-react";
import { formatTimestamp, getTimeRemainingAbbreviated, hasTimestampPassed } from "@palette.tools/utils";
import { cn } from "../shadcn/lib/utils";
import { TypographyH4, TypographyMedium, TypographySmall } from "../typography";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../shadcn/components/ui/tooltip";


export const DeadlineLabel: React.FC<{
  editable?: boolean,
  timestamp: number | undefined,
  size?: 'small' | 'medium' | 'large',
  isComplete?: boolean,
}> = ({
  editable = false,
  timestamp,
  size = 'medium',
  isComplete = false
}) => {

  const label = useMemo(() => {

    if (isComplete) {
      return <CalendarCheckIcon width={20} height={20} className="text-green-500" />
    }

    if (timestamp === undefined) {
      if (editable) {
        return <CalendarPlusIcon width={20} height={20} />
      }
      return <CalendarIcon width={20} height={20} />
    }

    const text: string = hasTimestampPassed(timestamp)
      ? "Past"
      : getTimeRemainingAbbreviated(timestamp);

    const className = cn(hasTimestampPassed(timestamp)
      ? "text-destructive"
      : "text-foreground");

    switch(size) {
      case 'small':
        return <TypographySmall className={className}>{text}</TypographySmall>
      case 'medium':
        return <TypographyMedium className={className}>{text}</TypographyMedium>
      case 'large':
        return <TypographyH4 className={className}>{text}</TypographyH4>
      default:
        return <TypographyMedium className={className}>{text}</TypographyMedium>
    }
  }, [timestamp, editable, isComplete]);

  return (
    <TooltipProvider>
      <Tooltip delayDuration={300}>
        <TooltipTrigger className={!editable ? "cursor-default": "cursor-pointer"} asChild>
          {label}
        </TooltipTrigger>
        <TooltipContent>
          {timestamp !== undefined ? formatTimestamp(timestamp) : "No deadline"}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
