"use client";

import { redirect_to_workspace_customer_portal } from "@palette.tools/api.client";
import { Workspace } from "@palette.tools/model.client";
import React, { useEffect, useState } from "react";


export interface CustomerPortalRedirectState {
  isLoading: boolean;
}

export function useCustomerPortalRedirect(workspace: Workspace | null) {

  const [state, setState] = useState<CustomerPortalRedirectState>({ isLoading: false });

  const redirect = async () => {
    if (!workspace) return;
    setState(prev => ({ ...prev, isLoading: true }));
    await redirect_to_workspace_customer_portal(workspace.id)
      .then(link => {
        if (link) {
          window.open(link, "_blank");
        }
      })
      .finally(() => {
        setState(prev => ({ ...prev, isLoading: false }));
      });
  };

  return { redirect, state }

}

export interface CustomerPortalRedirectTriggerProps<T extends React.ElementType> {
  children: React.ReactElement<React.ComponentPropsWithoutRef<T>>;
  workspace: Workspace | null;
  onUpdate?: (state: CustomerPortalRedirectState) => Promise<void> | void;
}


export function CustomerPortalRedirectTrigger<T extends React.ElementType>({
  children,
  workspace,
  onUpdate,
}: CustomerPortalRedirectTriggerProps<T>) {

  const { redirect, state } = useCustomerPortalRedirect(workspace);

  useEffect(() => {
    onUpdate?.(state)
  }, [JSON.stringify(state)]);

  const props: typeof children.props = {
    ...children.props,
    onClick() {
      redirect();
    }
  }

  const trigger = React.cloneElement(children, props);

  return <>{trigger}</>;

}
