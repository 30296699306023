"use client";

import React, { useState } from "react";
import { Modal } from "./Modal";
import { LoadingIcon } from "@palette.tools/react.icons";
import { TypographyMedium } from "../typography";
import { Input } from "../shadcn/components/ui/input";
import { Label } from "../shadcn/components/ui/label";
import { Workspace, transact, useAuth, id as uuid } from "@palette.tools/model.client";


export const CreateWorkspaceModal: React.FC<{
  open: boolean,
  onClose: () => void,
}> = ({
  open,
  onClose,
}) => {

  const { profile, isLoading: isLoadingProfile } = useAuth();

  // Regular hooks
  const [name, setName] = useState("");
  const [isCreating, setCreating] = useState(false);

  let content: React.ReactNode;
  if (isLoadingProfile) {
    content = <div className="w-[24px] h-[24px]"><LoadingIcon /></div>;
  }
  else if (profile) {
    content = <><Label htmlFor="name">Name</Label>
      <Input
        name="name"
        type="text"
        placeholder="Ex. Creativity Inc., MIT, etc..."
        onChange={(e) => setName(e.target.value)}
      /></>
  }
  else {
    content = <TypographyMedium className="text-destructive">An error occurred!</TypographyMedium>
  }

  return <Modal
    open={open}
    header={"Create Workspace"}
    description={"Your group, school or company."}
    primaryButtonContent={isCreating ? <><div className="w-[16px] h-[16px]"><LoadingIcon /></div>{" Creating"}</> : "Create"}
    secondaryButtonContent="Cancel"
    primaryButtonDisabled={!name.trim() || isLoadingProfile}
    onClose={() => {
      setName("");
      onClose();
    }}
    onSubmit={async () => {
      if (name.trim() && !isLoadingProfile && !!profile) {
        setCreating(true);
        const workspaceId = uuid();
        await transact(
          Workspace.create({ id: workspaceId, name: name.trim() }),
          profile.link("workspace", workspaceId),
          Workspace.assign_role(workspaceId, "admin", profile.id),
        ).finally(() => {
          setCreating(false);
          onClose();
        });
      }
    }}
  >
    {content}
    <div className="min-h-[10px]" />
  </Modal>

}
