"use client";

import React from "react";

export interface PanelDividerProps {
  direction?: "horizontal" | "vertical",
}

export const PanelDivider: React.FC<PanelDividerProps> = ({
  direction = "horizontal",
}) => {
  return <div style={{
    minWidth: "4px",
    minHeight: "4px",
    width: direction === "horizontal" ? "4px" : undefined, 
    height: direction === "horizontal" ? undefined : "4px",
  }}></div>
}