import { ThumbnailType } from "@palette.tools/model";
import { CornerUpLeftIcon, SaveIcon } from "lucide-react";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import ReactCrop, { Crop, PercentCrop, centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { ChooseFileButton } from "../forms/buttons/ChooseFileButton";
import { Button } from "../shadcn/components/ui/button";
import { Progress } from "../shadcn/components/ui/progress";


function toBlob(canvas: HTMLCanvasElement): Promise<Blob> {
  return new Promise((resolve) => {
    canvas.toBlob(blob => {
      if (blob) resolve(blob);
    }, "image/png");
  });
}


export interface ThumbnailEditorProps {
  url?: string,
  uncropped_url?: string,
  crop?: Crop,
  thumbnail_type?: ThumbnailType,
  saveFn: (croppedBlob: Blob, uncroppedBlob: Blob | null, crop: Crop, onSaveProgress: (progress: number) => void) => Promise<void>;
  disabled?: boolean,
  onSaveStateChanged?: (isSaved: boolean) => void;
}


function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
): Crop {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}


function compareCrops(cropA: Crop | undefined, cropB: Crop | undefined) {
  return (
    (cropA === undefined && cropB === undefined)
    || (
      cropA !== undefined
      && cropB !== undefined
      && cropA.unit === cropB.unit
      && cropA.height === cropB.height
      && cropA.width === cropB.width
      && cropA.x === cropB.x
      && cropA.y === cropB.y
    )
  );
}


export const ThumbnailEditor: React.FC<ThumbnailEditorProps> = (props) => {
  const [imageURL, setImageURL] = useState<string>(props.uncropped_url || "");
  const [crop, setCrop] = useState<Crop | undefined>(props.crop || undefined);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const croppedCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const uncroppedCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const [uploadError, setUploadError] = useState<Error | undefined>();
  const [isSaving, setIsSaving] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saveProgress, setSaveProgress] = useState(0);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const aspect = props.thumbnail_type === ThumbnailType.PROFILE_PICTURE
    ? 1
    : props.thumbnail_type === ThumbnailType.CINEMATIC_THUMBNAIL
      ? (16 / 9)
      : undefined;
  const originalCrop = props.crop;

  useEffect(() => {
    setUnsavedChanges(props.uncropped_url !== imageURL || !compareCrops(originalCrop, crop));
  }, [props.uncropped_url, imageURL, originalCrop, crop]);

  useEffect(() => {
    props.onSaveStateChanged?.(unsavedChanges);
  }, [unsavedChanges]);

  const onUpdateCrop = (_: Crop, percentCrop: PercentCrop) => {
    setCrop(percentCrop); // using percentCrop instead of pixel crop
  };

  const onImageLoad = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    imgRef.current = event.currentTarget;
    const newCrop = centerAspectCrop(imgRef.current.naturalWidth, imgRef.current.naturalHeight, aspect || (16 / 9));
    if (props.uncropped_url !== imgRef.current.getAttribute("src") || !props.crop) {
      setCrop(newCrop);
    } else {
      setCrop(props.crop);
    }
  };

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImageURL(reader.result as string);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  function onSaveProgress(progress: number) {
    setSaveProgress(progress);
  }

  async function save() {
    if (!crop || !crop.width || !crop.height || !croppedCanvasRef.current || !uncroppedCanvasRef.current || !imgRef.current) {
      return;
    }

    const imageWidth = imgRef.current.naturalWidth;
    const imageHeight = imgRef.current.naturalHeight;

    // Convert percentage crop to pixel values
    const pixelCrop = {
      x: (crop.x * imageWidth) / 100,
      y: (crop.y * imageHeight) / 100,
      width: (crop.width * imageWidth) / 100,
      height: (crop.height * imageHeight) / 100,
    };

    const croppedCanvas = croppedCanvasRef.current;
    const croppedCtx = croppedCanvas.getContext("2d");
    croppedCanvas.width = pixelCrop.width;
    croppedCanvas.height = pixelCrop.height;

    croppedCtx?.drawImage(
      imgRef.current,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    const croppedBlob = await toBlob(croppedCanvas);

    if (!croppedBlob) {
      return;
    }

    // Get uncropped blob, if new upload.

    let uncroppedBlob: Blob | null = null;

    if (imageURL !== props.uncropped_url) {

      const uncroppedCanvas = uncroppedCanvasRef.current;
      const uncroppedCtx = uncroppedCanvas.getContext("2d");
      uncroppedCanvas.width = imageWidth;
      uncroppedCanvas.height = imageHeight;

      uncroppedCtx?.drawImage(
        imgRef.current,
        0,
        0,
        imageWidth,
        imageHeight,
        0,
        0,
        imageWidth,
        imageHeight
      );

      uncroppedBlob = await toBlob(uncroppedCanvas);
    }

    setSaveProgress(0.1);
    setIsSaving(true);
    setUploadError(undefined);
    await props.saveFn(croppedBlob, uncroppedBlob, crop, onSaveProgress)
      .catch((error) => {
        setUploadError(error);
      })
      .finally(() => {
        setIsSaving(false);
        setSaveProgress(0);
      });
  }

  function resetCrop() {
    setCrop(originalCrop);
    setImageURL(props.uncropped_url || "");
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  }

  return (
    <div data-disabled={props.disabled} className="flex flex-col gap-x-[10px] items-center place-content-center data-[disabled=true]:opacity-50">

      <ChooseFileButton onChange={onSelectFile} />
      <div className="min-h-[20px] w-full" />

      {/* Image crop component */}
      {imageURL && (
        <div data-disabled={props.disabled} className="w-full h-full flex flex-row items-center place-content-center">
          <ReactCrop
            disabled={props.disabled}
            className="max-h-[300px] max-w-[300px]"
            crop={crop}
            onChange={onUpdateCrop}
            circularCrop={props.thumbnail_type === ThumbnailType.PROFILE_PICTURE ? true : false}
            aspect={aspect}
          >
            <img crossOrigin="anonymous" alt="Crop" src={imageURL} onLoad={onImageLoad} />
          </ReactCrop>
        </div>
      )}

      <div className="w-full flex flex-row items-center place-content-center">
        {isSaving
          ? <Progress value={saveProgress * 100} />
          : <>
            <Button disabled={props.disabled || !unsavedChanges} variant="ghost" onClick={resetCrop}>
              <CornerUpLeftIcon className="h-[16px] w-[16px]" />&nbsp;Reset Crop
            </Button>

            <Button disabled={props.disabled || !unsavedChanges} variant="ghost" onClick={save}>
              <SaveIcon className="h-[15px] w-[15px]" />&nbsp;Save
            </Button>
          </>}
      </div>

      {/* Hidden canvas to get the cropped image */}
      <canvas
        ref={croppedCanvasRef}
        style={{ display: "none" }}
      ></canvas>
      {/* Hidden canvas to get the uncropped image */}
      <canvas
        ref={uncroppedCanvasRef}
        style={{ display: "none" }}
      ></canvas>
    </div>
  );
};
