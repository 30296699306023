"use client";

import React from "react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuRadioGroup, DropdownMenuRadioItem, DropdownMenuTrigger } from "../shadcn/components/ui/dropdown-menu";
import { Button } from "../shadcn/components/ui/button";
import { ProjectRoleLabels, ProjectRoles } from "@palette.tools/model";
import { DropdownOptions } from "./options";
import { cn } from "../shadcn/lib/utils";
import { ChevronDownIcon } from "lucide-react";


export const ProjectRolesDropdownOptions: DropdownOptions<ProjectRoles> = new Map([

  [ProjectRoles.admin, {
    key: ProjectRoles.admin,
    label: ProjectRoleLabels[ProjectRoles.admin],
    className: "bg-blue-500 hover:bg-blue-700"
  }],

  [ProjectRoles.editor, {
    key: ProjectRoles.editor,
    label: ProjectRoleLabels[ProjectRoles.editor],
    className: "bg-blue-500 hover:bg-blue-700"
  }],

  [ProjectRoles.commenter, {
    key: ProjectRoles.commenter,
    label: ProjectRoleLabels[ProjectRoles.commenter],
    className: "bg-blue-500 hover:bg-blue-700"
  }],

]);

export const ProjectRolesDropdownMenu: React.FC<{
  editable?: boolean,
  selectedRole?: ProjectRoles,
  onSelectRole?: (selectedStatus: ProjectRoles) => void,
}> = ({
  editable = true,
  selectedRole = ProjectRoles.commenter,
  onSelectRole,
}) => {
  const selectedOption = ProjectRolesDropdownOptions.get(selectedRole) || ProjectRolesDropdownOptions.get(ProjectRoles.commenter);


  const button = <Button
    disabled={!editable}
    className={cn(selectedOption!.className, "disabled:opacity-100 rounded-xl w-[160px]")}
  >
    {selectedOption!.label}
    {editable ? <ChevronDownIcon className="ml-2 h-4 w-4" /> : undefined}
  </Button>

  if (!editable) return button;

  return <DropdownMenu>
    <DropdownMenuTrigger asChild>
      {button}
    </DropdownMenuTrigger>
    <DropdownMenuContent className="w-56">
      <DropdownMenuRadioGroup
        value={selectedRole?.toString()}
        onValueChange={(value: any) => onSelectRole && onSelectRole(+value as ProjectRoles)}
      >
        {Array.from(ProjectRolesDropdownOptions.entries()).map(
          ([key, value]) => <DropdownMenuRadioItem
            key={key.toString()}
            value={key.toString()}
          >{value.label}</DropdownMenuRadioItem>)
        }
      </DropdownMenuRadioGroup>
    </DropdownMenuContent>
  </DropdownMenu>

}
