import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogTitle } from "@palette.tools/react/src/shadcn/components/ui/dialog";
import { Button } from '@palette.tools/react';

interface PopupModalProps {
  open: boolean;
  header?: string;
  description?: string;
  primaryButtonClass?: string;
  primaryButtonContent?: React.ReactNode;
  secondaryButtonClass?: string;
  secondaryButtonContent?: React.ReactNode;
  closeDisabled?: boolean;
  children?: React.ReactNode;
  primaryButtonDisabled?: boolean;
  secondaryButtonDisabled?: boolean;
  containerClassName?: string;
  onClose: () => void;
  onCancel?: () => void;
  onSubmit?: () => Promise<void>;
}

export const Modal: React.FC<PopupModalProps> = (props) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [clonedChildren, setClonedChildren] = useState<React.ReactNode>(null);

  useEffect(() => {
    if (props.open) {
      setIsAnimating(false); // Reset the flag when opening
    } else if (!props.open && !isAnimating) {
      setIsAnimating(true); // Set the flag when closing starts
      setClonedChildren(React.Children.map(props.children, (child) => {
        if (React.isValidElement<{style: React.CSSProperties}>(child)) {
          return React.cloneElement(child, {
            style: {
              ...child.props.style,
              pointerEvents: 'none',
            },
          });
        }
        return child;
      }));
      // Wait for the animation to finish, then call onClose
      setTimeout(() => {
        setIsAnimating(false);
        setClonedChildren(null);
        props.onClose();
      }, 300); // Assuming the animation takes 300ms
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      modal
      onOpenChange={() => {
        if (!isAnimating) {
          props.onClose();
        }
      }}
    >
      <DialogContent
        onInteractOutside={e => {
          if (props.closeDisabled) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        closehidden={JSON.stringify(props.closeDisabled)}
      >
        {props.header && <DialogTitle><span className="text-lg">{props.header}</span></DialogTitle>}
        {props.description && <DialogDescription><span className="text-md">{props.description}</span></DialogDescription>}
        <form onSubmit={async (e) => {
          e.preventDefault();
          props.onSubmit && await props.onSubmit();
        }}>
          <div className={props.containerClassName} style={isAnimating ? { pointerEvents: 'none' } : {}}>
            {isAnimating ? clonedChildren : props.children}
          </div>
          <DialogFooter className="flex flex-row">
            {props.secondaryButtonContent &&
              <Button
                type="button"
                variant="outline"
                className={props.secondaryButtonClass}
                disabled={props.secondaryButtonDisabled || isAnimating}
                onClick={() => props.onCancel ? props.onCancel() : props.onClose()}
              >
                {props.secondaryButtonContent}
              </Button>}
            <div className="flex-1" />
            {props.primaryButtonContent &&
              <Button
                type="submit"
                variant="default"
                className={props.primaryButtonClass}
                disabled={props.primaryButtonDisabled || isAnimating}
              >
                {props.primaryButtonContent}
              </Button>}
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
