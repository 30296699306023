import React from "react";
import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
 
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}


type HeadingProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;
type PProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>, 
  HTMLParagraphElement
>;
type SpanProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>, 
  HTMLSpanElement
>;
type SmallProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>, 
  HTMLElement
>;


export const TypographyH1 = React.forwardRef<HTMLHeadingElement, HeadingProps>((props, ref) => {
  const resolvedProps: HeadingProps = {
    ...props,
    className: cn(
      "scroll-m-20 font-degular-display font-bold text-[68px]/[70px] tracking-tight",
      props.className,
    )
  };
  return <h1 ref={ref} {...resolvedProps} />;
});

export const TypographyH2 = React.forwardRef<HTMLHeadingElement, HeadingProps>((props, ref) => {
  const resolvedProps: HeadingProps = {
    ...props,
    className: cn(
      "scroll-m-20 font-degular border-b pb-2 text-[35px] font-semibold tracking-tight transition-colors first:mt-0",
      props.className,
    )
  };
  return <h2 ref={ref} {...resolvedProps} />;
});

export const TypographyH3 = React.forwardRef<HTMLHeadingElement, HeadingProps>((props, ref) => {
  const resolvedProps: HeadingProps = {
    ...props,
    className: cn(
      "scroll-m-20 font-degular text-[27px] font-semibold tracking-tight",
      props.className,
    )
  };
  return <h3 ref={ref} {...resolvedProps} />;
});

export const TypographyH4 = React.forwardRef<HTMLHeadingElement, HeadingProps>((props, ref) => {
  const resolvedProps: HeadingProps = {
    ...props,
    className: cn(
      'font-inter scroll-m-20 text-xl font-semibold tracking-tight',
      props.className,
    )
  };
  return <h4 ref={ref} {...resolvedProps} />;
});

export const TypographyH5 = React.forwardRef<HTMLHeadingElement, HeadingProps>((props, ref) => {
  const resolvedProps: HeadingProps = {
    ...props,
    className: cn(
      'font-inter scroll-m-18 text-lg font-semibold tracking-tight',
      props.className,
    )
  }
  return <h4 ref={ref} {...resolvedProps} />
});

export const TypographyP = React.forwardRef<HTMLParagraphElement, PProps>((props, ref) => {
  const resolvedProps: PProps = {
    ...props,
    className: cn(
      'font-inter text-sm leading-7 [&:not(:first-child)]:mt-6',
      props.className,
    ),
  };
  return <p ref={ref} {...resolvedProps} />;
});

export const TypographyMedium = React.forwardRef<HTMLSpanElement, SpanProps>((props, ref) => {
  const resolvedProps: SpanProps = {
    ...props,
    className: cn(
      'font-inter text-base font-regular',
      props.className,
    ),
  };
  return <span ref={ref} {...resolvedProps} />;
});

export const TypographySmall = React.forwardRef<HTMLElement, SmallProps>((props, ref) => {
  const resolvedProps: SmallProps = {
    ...props,
    className: cn(
      'font-inter text-sm font-regular',
      props.className,
    ),
  };
  return <small ref={ref} {...resolvedProps} />;
});

export const TypographyXSmall = React.forwardRef<HTMLElement, SmallProps>((props, ref) => {
  const resolvedProps: SmallProps = {
    ...props,
    className: cn(
      'font-inter text-xs/[14px] font-regular',
      props.className,
    ),
  };
  return <small ref={ref} {...resolvedProps} />;
});

// font-size: 14px
// line-height: 20px
export const TypographyMuted = React.forwardRef<HTMLParagraphElement, PProps>((props, ref) => {
  const resolvedProps: PProps = {
    ...props,
    className: cn(
      "text-sm text-muted-foreground",
      props.className,
    ),
  };
  return <p ref={ref} {...resolvedProps} />;
});

export const TypographyShortcutSymbol = React.forwardRef<HTMLSpanElement, SpanProps>((props, ref) => {
  const resolvedProps: SpanProps = {
    ...props,
    className: cn(
      `text-[9px] px-1 rounded border-[0.5px] border-muted-foreground/50 text-muted-foreground/50 border-solid select-none`,
      props.className,
    ),
  };
  return <span ref={ref} {...resolvedProps} />;
});

export const TypographyShortcutText = React.forwardRef<HTMLSpanElement, SpanProps>((props, ref) => {
  const resolvedProps: SpanProps = {
    ...props,
    className: cn(
      `text-[11px] px-1 text-muted-foreground/50 border-solid select-none`,
      props.className,
    ),
  };
  return <span ref={ref} {...resolvedProps} />;
});