

export function isPlayableVideo(mimeType: string | undefined | null) {

  if (!mimeType) return false;

  // Array of commonly supported video MIME types
  const playableVideoTypes = [
    "mp4",
    "video/mp4",
    "webm",
    "video/webm",
    "ogg",
    "video/ogg",
    "mov",
    "quicktime",
    "video/quicktime",
    "avi",
    "x-msvideo",
    "video/x-msvideo",
  ];

  // Check if the provided MIME type is in the array
  return playableVideoTypes.includes(mimeType.toLowerCase());
}