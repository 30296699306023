"use client";

import React, { useEffect, useRef, useState } from "react";
import { usePanelItem } from "./PanelItemProvider";
import { PanelEdges } from "./PanelEdges";
import { TitleButton } from "./TitleButton";
import { cn } from "../../shadcn/lib/utils";


export interface PanelProps {
  className?: string;
  innerClassName?: string;
  children?: React.ReactNode;
  title?: string;
  enhancer?: React.ReactNode;
  show_container?: boolean;
  show_title?: boolean;
  weight?: number;
  grow?: boolean;
  outerEdges?: PanelEdges;
  collapsible?: boolean;
  cornerComponent?: React.ReactNode;
}


export const Panel: React.FC<PanelProps> = React.memo(({
  className,
  innerClassName,
  children,
  title = '',
  enhancer,
  show_container = true,
  show_title = true,
  weight,
  grow = false,
  outerEdges = { top: false, right: false, left: false, bottom: false },
  collapsible = false,
  cornerComponent,
}) => {
  const HEIGHT_ADJUSTMENT = 5;

  const { collapsed, canCollapse, setCollapsed, direction, position } = usePanelItem();

  const computedCollapsible = canCollapse !== undefined ? canCollapse : collapsible;

  // Calculate title button height
  const titleButtonRef = useRef<HTMLDivElement>(null);
  const collapsedTitleButtonRef = useRef<HTMLDivElement>(null);
  const [titleButtonHeightDifference, setTitleButtonHeightDifference] = useState<number>(0);
  useEffect(() => {
    if (titleButtonRef.current && collapsedTitleButtonRef.current) {
      const expandedHeight = titleButtonRef.current.getBoundingClientRect().height;
      const collapsedHeight = collapsedTitleButtonRef.current.getBoundingClientRect().height;
      setTitleButtonHeightDifference(expandedHeight - collapsedHeight);
    }
  }, [title, enhancer, children]);  // re-run when these props change, or others as needed


  // Ensure remains same size on major axis when collapsed
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentDimensions, setContentDimensions] = useState<{width?: number, height?: number}>({
    width: undefined, height: undefined
  });

  useEffect(() => {
    if (contentRef.current) {
      const rect = contentRef.current.getBoundingClientRect();
      setContentDimensions({ width: rect.width, height: rect.height });
    }
  }, [children]);

  const layoutStyle = cn("flex flex-col gap-[5px] p-2 rounded-md bg-muted/50",
    show_container ? "p-2" : undefined, // only add padding if show_container is true
  )

  const borderRadiusStyle = cn(
    outerEdges.top && outerEdges.left ? "rounded-tl-lg" : "rounded-tl-none",
    outerEdges.top && outerEdges.right ? "rounded-tr-lg" : "rounded-tr-none",
    outerEdges.bottom && outerEdges.left ? "rounded-bl-lg" : "rounded-bl-none",
    outerEdges.bottom && outerEdges.right ? "rounded-br-lg" : "rounded-br-none",
  )

  const bgStyle = cn(
    show_container ? "bg-zinc-900/70" : "bg-transparent",
  )

  const collapsedChildrenStyle = cn(
    !collapsed ? "flex-1 min-h-0 min-w-0" : undefined,
    collapsed ? "absolute left-[-9999px] top-[-9999px] visibility-visible overflow-visible" : undefined,
    collapsed && contentDimensions.height && direction === "horizontal" ? `h-[${contentDimensions.height}px]` : undefined,
    collapsed && contentDimensions.width && direction === "vertical" ? `w-[${contentDimensions.width}px]` : undefined,
  )

  const contentSpacerStyle = cn(
    direction === "vertical" && contentDimensions.height && collapsed ? `h-[${contentDimensions.height + titleButtonHeightDifference + (titleButtonHeightDifference ? HEIGHT_ADJUSTMENT : 0)}px]` : undefined,
    direction === "horizontal" && contentDimensions.width && collapsed ? undefined : `w-[${contentDimensions.width}px]`,
  )

  return (
    <div className={cn(className, layoutStyle, borderRadiusStyle, bgStyle)} style={{flexGrow: collapsed ? undefined : weight}}>
      {title && show_title && <>
        <div ref={titleButtonRef} style={{alignSelf: "flex-start", position: 'absolute', visibility: 'hidden', zIndex: -1}}>
          <TitleButton
            title={title}
            enhancer={enhancer}
            collapsible={computedCollapsible}
            collapsed={false}  // render in the non-collapsed state off-screen
            onClick={() => setCollapsed(!collapsed)}
            position={position}
            direction={direction}
          />
        </div>

        <div ref={collapsedTitleButtonRef} style={{alignSelf: "flex-start", position: 'absolute', visibility: 'hidden', zIndex: -1}}>
          <TitleButton
            title={title}
            enhancer={enhancer}
            collapsible={computedCollapsible}
            collapsed={true}  // render in the collapsed state off-screen
            onClick={() => setCollapsed(!collapsed)}
            position={position}
            direction={direction}
          />
        </div>

        <div style={{alignSelf: "flex-start"}} className="flex flex-row w-full">
          <TitleButton
            title={title}
            enhancer={enhancer}
            collapsible={computedCollapsible}
            collapsed={collapsed}
            onClick={() => setCollapsed(!collapsed)}
            position={position}
            direction={direction}
          />
          <div className="flex-1" />
          <div>{cornerComponent}</div>
        </div>
        <div className={contentSpacerStyle}></div>
      </>}
      <div className={cn(innerClassName, collapsedChildrenStyle)} ref={contentRef}>
        {children}
      </div>
    </div>
  );
});
