"use client";

import React, { useState } from "react";
import { Modal } from "./Modal";
import { LoadingIcon } from "@palette.tools/react.icons";
import { TypographyMedium } from "../typography";
import { Asset, Category, Project, Task, Workspace, useAuth, usePermissions } from "@palette.tools/model.client";
import { delete_entity } from "@palette.tools/api.client";



export const DeleteTaskModal: React.FC<{
  open: boolean,
  onClose: () => void,
  workspace: Workspace | null,
  project: Project | null,
  category: Category | null,
  asset: Asset | null,
  task: Task | null,
}> = ({
  open,
  onClose,
  workspace,
  project,
  category,
  asset,
  task,
}) => {

  const { profile, isLoading: isLoadingProfile } = useAuth();

  // Permissions hooks.
  const { canDeleteTask } = usePermissions({ workspace, project, category, asset, task });

  // Regular hooks
  const [isDeleting, setDeleting] = useState(false);

  let content: React.ReactNode;
  if (isLoadingProfile) {
    content = <div className="w-[24px] h-[24px]"><LoadingIcon /></div>;
  }
  else if (profile) {
    if (!canDeleteTask) {
      content = <TypographyMedium className="italic">You are not allowed to delete this task.</TypographyMedium>
    }
    else {
      content = <TypographyMedium>You will lose all conversations, links, and files!</TypographyMedium>
    }
  }
  else {
    content = <TypographyMedium className="text-destructive">An error occurred!</TypographyMedium>
  }

  const submitDisabled = (
    !canDeleteTask
    || !task
    || isLoadingProfile
  );

  return <Modal
    open={open}
    header={"Permanently Delete Task"}
    description={"This CANNOT be undone!"}
    primaryButtonClass="bg-destructive hover:bg-destructive/50 text-destructive-foreground"
    primaryButtonContent={isDeleting ? <><div className="w-[16px] h-[16px]"><LoadingIcon /></div>{" Deleting"}</> : "Delete"}
    secondaryButtonContent="Cancel"
    primaryButtonDisabled={submitDisabled}
    onClose={() => {
      onClose();
    }}
    onSubmit={async () => {
      if (!submitDisabled && !!task) {
        setDeleting(true)
        await delete_entity('task', task.id)
          .finally(() => {
            setDeleting(false);
            onClose && onClose();
          });
      }
    }}
  >
    {content}
    <div className="min-h-[10px]" />
  </Modal>

}
