import React from 'react';
import {
  Button,
  TypographyShortcutSymbol,
  TypographyShortcutText,
} from "@palette.tools/react";


import { LoadingIcon } from '@palette.tools/react.icons';


interface PasswordFieldsProps {
  children?: React.ReactNode,
  isSubmitting?: boolean,
  className?: string,
  disabled?: boolean,
}


export const SubmitButton: React.FC<PasswordFieldsProps> = ({
  children = "Submit",
  isSubmitting = false,
  className,
  disabled = false,
}) => {

  return (
    <div className="flex flex-col items-end gap-0.5">
      <Button className={className} type="submit" disabled={disabled}>{
        isSubmitting
        ? <div className="w-[16px] h-[16px]"><LoadingIcon /></div>
        : <>{children}</>
      }</Button>
    </div>
  );
};