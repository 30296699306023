"use client";

import React from "react";
import { TaskState } from "firebase/storage";
import { Project, Workspace, uploadFile, validateWorkspaceFile } from "@palette.tools/model.client";
import { ProgressModal } from "../modals/ProgressModal";
import { InfoModal } from "../modals/InfoModal";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../shadcn/components/ui/tooltip";
import { flushSync } from "react-dom";

export interface FileUploadValidation {
  isValid: boolean;
  reason?: string;
}

export const useFileUpload = (
  workspace: Workspace | null,
  project: Project | null,
  onValidateFile: (f: File) => FileUploadValidation = (f) => validateWorkspaceFile(workspace, f),
) => {
  const canUpload = !!workspace && !!project;
  const [progress, setProgress] = React.useState<number>(0);
  const [validationError, setValidationError] = React.useState<string>("");
  const [uploadError, setUploadError] = React.useState<string>("");
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [fileId, setFileId] = React.useState<string | null>(null);

  const upload = async (
    file: File,
    callbacks?: {
      onCreateEntry?: (fileId: string) => void;
      onStart?: () => void;
      onProgress?: (state: TaskState, bytesTransferred: number, totalBytes: number) => void;
      onFinish?: (fileId: string | null) => void;
    }
  ) => {
    if (!canUpload) return;

    const validation = onValidateFile(file);
    if (!validation.isValid) {
      setValidationError(validation.reason || "File cannot be uploaded.");
      return;
    }

    flushSync(() => {
      setUploadError("");
      setIsUploading(true);
    });
    callbacks?.onStart?.();
    setProgress(0.05);
    await uploadFile(
      workspace,
      project,
      file,
      (_fileId) => {
        callbacks?.onCreateEntry?.(_fileId);
        setFileId(_fileId);
      },
      (state, bytesTransferred, totalBytes) => {
        const adjusted_progress = ((bytesTransferred / totalBytes) * 0.90) + 0.05;
        setProgress(adjusted_progress);
        callbacks?.onProgress?.(state, bytesTransferred, totalBytes);
      }
    )
    .catch((e) => setUploadError(e.message))
    .finally(() => {
      setIsUploading(false);
      callbacks?.onFinish?.(fileId);
    });
  };

  return {
    canUpload,
    progress,
    validationError,
    uploadError,
    isUploading,
    fileId,
    upload,
  };
};

interface FileUploadTriggerProps<T extends React.ElementType> {
  workspace: Workspace | null;
  project: Project | null;
  children: React.ReactElement<React.ComponentPropsWithoutRef<T>>;
  disabled?: boolean;
  onValidateFile?: (f: File) => FileUploadValidation;
  onCreateEntry?: (fileId: string) => void;
  onStart?: () => void;
  onProgress?: (state: TaskState, bytesTransferred: number, totalBytes: number) => void;
  onFinish?: (fileId: string | null) => void;
}

export const FileUploadTrigger = <T extends React.ElementType>({
  workspace,
  project,
  children,
  disabled,
  onValidateFile,
  onCreateEntry,
  onStart,
  onProgress,
  onFinish,
}: FileUploadTriggerProps<T>) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { isUploading, validationError, uploadError, progress, upload } = useFileUpload(workspace, project, onValidateFile);

  const props: typeof children.props = {
    ...children.props,
    onClick() {
      if (disabled || !inputRef.current) return;
      inputRef.current.value = "";
      inputRef.current.click();
    }
  };

  const handleFileChange = async () => {
    if (!inputRef.current || !inputRef.current.files || inputRef.current.files.length < 1) {
      return;
    }
    const f = inputRef.current.files[0];
    upload(f, { onCreateEntry, onStart, onProgress, onFinish });
  };

  const clone = React.cloneElement(children, props);

  const trigger = disabled
    ? <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger asChild>{clone}</TooltipTrigger>
          <TooltipContent className="italic text-muted-foreground">Workspace not set up for uploading.</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    : clone;

  return (
    <>
      <input ref={inputRef} type="file" style={{ display: "none" }} onChange={handleFileChange} />
      {isUploading ? <ProgressModal open={isUploading} progress={progress} error={uploadError}>Uploading...</ProgressModal> : null}
      <InfoModal open={!!validationError}>{validationError}</InfoModal>
      {trigger}
    </>
  );
};
